import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';
import { Typography } from '@mui/material';

import { currencyFormatter } from '@lendica/utils';

export const ProgressTimeline = ({ schedules }) => {
    return (
        <Timeline align="alternate">
            {schedules &&
                schedules.length &&
                schedules.map((item, index) => (
                    <TimelineItem key={`timeline-${index}`}>
                        <TimelineSeparator>
                            <TimelineDot
                                variant={item.status === 'inprogress' ? 'outlined' : 'default'}
                                color={item.status === 'scheduled' ? 'grey' : 'secondary'}
                            />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography>{item.actual_date}</Typography>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >{`Amount: ${currencyFormatter(item.pmt)}`}</Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >{`Estimated: ${item.expected_date}`}</Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >{`Status: ${item.status}`}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))}
        </Timeline>
    );
};
