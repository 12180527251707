import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Header, Body } from '../../../../components';
import { BottomButton, Toolbar } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    bottomButton: {
        width: '100%',
        borderRadius: 20,
        height: 36,
        padding: 0,
        boxShadow: 'none',
    },
}));

export const WithdrawalFailure = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { updateDrawDown } = DrawDownContainer.useContainer();

    const handleGoBack = () => {
        navigate('/3');
        updateDrawDown();
    };

    return (
        <>
            <Header
                title="Withdrawal"
                showLogo={false}
                showClose={false}
                leftActions={
                    <Button onClick={handleGoBack} className={classes.button}>
                        Done
                    </Button>
                }
                rightActions={<Box width={64}></Box>}
            />
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column" mt={15}>
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            maxWidth={345}
                            color="textSecondary"
                        >
                            Sorry, we could not complete the transaction at this time.
                            <br />
                            Please try again later
                        </Typography>
                        <Box my={6}>
                            <svg
                                width="110"
                                height="111"
                                viewBox="0 0 110 111"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M91.6465 9.3418H18.9798C13.984 9.3418 9.9419 13.4668 9.9419 18.5085L9.89648 101.008L28.0632 82.6751H91.6465C96.6423 82.6751 100.73 78.5501 100.73 73.5085V18.5085C100.73 13.4668 96.6423 9.3418 91.6465 9.3418ZM59.8548 64.3418H50.7715V55.1751H59.8548V64.3418ZM59.8548 46.0085H50.7715V27.6751H59.8548V46.0085Z"
                                    fill="#ED7A56"
                                />
                            </svg>
                        </Box>
                    </Box>
                </Box>
            </Body>
            <Toolbar
                action={
                    <BottomButton
                        leftActions={
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.bottomButton}
                                onClick={() => window.open('https://home.golendica.com/#contact')}
                            >
                                Contact us
                            </Button>
                        }
                        rightActions={
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.bottomButton}
                                onClick={handleGoBack}
                            >
                                Ok, Take me back
                            </Button>
                        }
                    />
                }
            />
        </>
    );
};
