const PayLaterPopup = ({ url, isOpen, onComplete, closeHandler }) => {
    const onClose = () => {
        closeHandler();
        onComplete();
    };

    return (
        <div
            className="pay-later-popup"
            style={{
                height: '100vh',
                width: '100vw',
                display: isOpen ? 'flex' : 'none',
                zIndex: 5,
                position: 'fixed',
                top: 0,
                left: 0,
                background: 'rgba(144, 144, 144, .95)',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '3rem 0',
                boxSizing: 'border-box',
            }}
        >
            <div style={{ position: 'relative', height: '100%', width: 'fit-content' }}>
                <button
                    id="popup-close-btn"
                    className="close-btn"
                    onClick={onClose}
                    // style={{ position: 'absolute', top: '1rem', right: '1rem', fontSize: 24, background: 'none', border: 'none', color: '#FFF'}}
                    // style={{
                    //     position: 'absolute', top: '-2.5rem', left: 0, fontSize: 14, fontWeight: 500, border: 'none', color: '#2E3192', zIndex: 4, margin: 0, background: '#FFF', padding: '0.5rem', bordserRadius: 30
                    // }}
                    style={{
                        position: 'absolute',
                        top: '-2.5rem',
                        right: 0,
                        fontSize: 16,
                        fontWeight: 500,
                        border: 'none',
                        zIndex: 4,
                        margin: 0,
                        color: '#FFF',
                        padding: '0.5rem',
                        borderRadius: 30,
                        background: 'none',
                        cursor: 'pointer',
                    }}
                >
                    Close
                </button>
                <iframe
                    src={url}
                    height="100%"
                    width="480px"
                    style={{ border: 'none', borderRadius: '4px' }}
                />
            </div>
        </div>
    );
};
export default PayLaterPopup;
