import React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100vh',
    },
}));

export const Loader = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <CircularProgress m={2} color="secondary" />
            <Typography variant="subtitle1" color="secondary" align="center">
                Actively retrieving your amount qualified...
            </Typography>
        </Box>
    );
};
