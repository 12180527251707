import { Typography, Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { currencyFormatter } from '@lendica/utils';
import { timeAgo, isAllVOD } from '../../../utils';

import { useAppContext, useDataContext } from '../../../AppContext';
import { SidebarPanel, SidebarPanelAlt } from '../../components';

import { refreshInvoices } from '../../../api';
import { updateInvoiceCache } from '../../../App';

const useStyles = makeStyles(theme => ({
    header: {
        'color': theme.palette.primary.main,
        'textAlign': 'center',
        '& > span': {
            fontWeight: theme.typography.fontWeightBold,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 19,
        },
    },
    box: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.action.disabled,
        borderRadius: 8,
    },
}));

export const FNPostHeader = () => {
    const classes = useStyles();

    const {
        appState: { invoiceIsLoading, lastRefresh },
        setAppState,
    } = useAppContext();
    const [data, setData] = useDataContext();

    const refresh = async () => {
        try {
            setAppState(currentState => ({
                ...currentState,
                invoiceIsLoading: true,
            }));
            const newInitData = await refreshInvoices(data?.company?.lendica_token ?? null);
            if (
                isAllVOD([
                    newInitData?.company,
                    newInitData?.fundnow?.summary,
                    newInitData?.fundnow?.invoices,
                    newInitData?.paylater?.summary,
                    newInitData?.paylater?.invoices,
                ])
            ) {
                updateInvoiceCache(newInitData?.fundnow);
                setData(currentState => ({ ...currentState, ...newInitData }));
                setAppState(currentState => ({
                    ...currentState,
                    lastRefresh: Date(),
                }));
            } else {
                throw new Error('Invalid data returned.');
            }
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't refresh the data. Please try again later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({
                ...currentState,
                invoiceIsLoading: false,
            }));
        }
    };

    return (
        <>
            <Box my={2} py={2} mx={2} className={classes.box}>
                <Typography variant="h5" className={classes.header}>
                    Get paid on invoices <span>now</span>
                </Typography>
            </Box>
            <Box mt={2} px={2}>
                <SidebarPanelAlt
                    infoList={[
                        {
                            title: 'Live deals',
                            subtitleAmount:
                                data?.fundnow?.summary?.number_of_live_deals ?? 'Unavailable',
                        },
                        {
                            title: 'Credit left',
                            subtitleAmount: currencyFormatter(
                                data?.fundnow?.summary?.funding_credit_left ?? 'Unavailable'
                            ),
                        },
                        {
                            title: `Advance`,
                            subtitleAmount: currencyFormatter(
                                data?.fundnow?.summary?.funding_credit_used ?? 'Unavailable'
                            ),
                        },
                    ]}
                />
            </Box>
            <Box
                mt={2}
                px={2}
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                alignContent="flex-end"
            >
                <SidebarPanel
                    buttonLabel="Refresh Invoices"
                    buttonId="btn-refresh-invoices"
                    title="Unpaid invoices"
                    subtitle=""
                    subtitleAmount={currencyFormatter(
                        data?.fundnow?.summary?.invoice_total ?? 'Unavailable'
                    )}
                    onClick={refresh}
                    disabled={invoiceIsLoading}
                    underline={lastRefresh && `Last updated ${timeAgo(lastRefresh)}`}
                />
            </Box>
        </>
    );
};
