import { IconButton, Box, Button, Typography, Collapse } from '@mui/material';
import { ArrowForward, Close } from '@mui/icons-material';
import { Alert } from '@mui/material';

export const PushMsg = ({ data, onClose, open, ...rest }) => {
    const { title, content, to } = data;

    return (
        <Collapse in={open}>
            <Alert icon={false} {...rest}>
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" color="textPrimary">
                            {title}
                        </Typography>
                        <IconButton onClick={onClose} size="small">
                            <Close />
                        </IconButton>
                    </Box>
                    <Box pt={4} pb={3}>
                        <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400 }}
                            color="textPrimary"
                        >
                            {content}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button
                            color="secondary"
                            href={to}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more
                        </Button>
                        <IconButton
                            size="small"
                            href={to}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ArrowForward />
                        </IconButton>
                    </Box>
                </Box>
            </Alert>
        </Collapse>
    );
};
