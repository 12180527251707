// import { postRequest } from '../../../api';

// export const plOnboardingAPI = {
//     async createApplication(lendica_token) {
//         return postRequest('/pod/paylater/apply', { lendica_token });
//     },
// };

import { getBackendURL } from '@lendica/utils';

export const useApi = env => {
    function parseToJsonPromise(data) {
        return new Promise(res => {
            data.json()
                .then(object => {
                    let response = {
                        successful: [200, 201, 204].includes(data.status),
                        data: object,
                        code: data.status,
                    };
                    return res(response);
                })
                .catch(error => {
                    res({
                        successful: false,
                        data: undefined,
                        code: data.status,
                    });
                });
        });
    }

    const apiGenerator = async (url, uri, method, payload, type) => {
        let headers = {
            'Content-type': 'application/json',
        };

        const params = type ? '?' + new URLSearchParams(type) : '';

        return fetch(`${url}${uri}${params}`, {
            method,
            body: payload ? JSON.stringify(payload) : null,
            headers,
        })
            .then(data => {
                return parseToJsonPromise(data);
            })
            .catch(error => {
                console.log('Fetch error', error);

                return {
                    successful: false,
                    data: undefined,
                    code: error.status_code,
                };
            });
    };

    const ApiGenerator = (uri, method, payload, type) => {
        return apiGenerator(getBackendURL(env), uri, method, payload, type);
    };

    const networkAdapter = generator => {
        return {
            POST: (url, payload, type) => generator(url, 'POST', payload, type),
            PUT: (url, payload, type) => generator(url, 'PUT', payload, type),
            PATCH: (url, payload, type) => generator(url, 'PATCH', payload, type),
            DELETE: (url, payload, type) => generator(url, 'DELETE', payload, type),
            GET: (url, type) => generator(url, 'GET', null, type),
        };
    };

    /**
     *
     * @type {{POST: ((p1?:*, p2?:*)), PUT: ((p1?:*, p2?:*)), PATCH: ((p1?:*, p2?:*)), DELETE: ((p1?:*)), GET: ((p1?:*))}}
     * @private
     */

    const _requestAPI = networkAdapter(ApiGenerator);

    return {
        baseUrl: getBackendURL(env),
        createApplication(lendica_token) {
            return _requestAPI.POST('/pod/paylater/apply', { lendica_token });
        },
    };
};
