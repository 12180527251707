import { useState, useEffect, useRef } from 'react';

export const useData = (getData, navigate, ...rest) => {
    const unmounted = useRef(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(getData(navigate, ...rest));
        return () => {
            unmounted.current = true;
        };
    }, []);

    return data;
};
