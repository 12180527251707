import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Overlay,
    OverlayFieldset,
    OverlayKeypoint,
    OverlayOption,
    OverlaySwitch,
    OverlayAction,
    Terms,
    FootNote,
} from '@lendica/components';
import { Loader } from '../../components';
import { percentageFormatter } from '@lendica/utils';
import { useAppContext, useDataContext } from '../../../AppContext';

import { activateSubmit } from '../../../api';

export const PLActivate = () => {
    const {
        appState: { plIsLoading },
        setAppState,
        updateData,
    } = useAppContext();
    const [data, setData] = useDataContext();

    const [maxPeriod, setMaxPeriod] = useState(60);
    const [passFee, setPassFee] = useState(true);
    const [agreeTerms, setAgreeTerms] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            setAppState(currentState => ({ ...currentState, plIsLoading: true }));
            if (data?.company?.lendica_token) {
                await activateSubmit(
                    {
                        lendica_token: data.company.lendica_token,
                        approval: {
                            max_period: maxPeriod,
                            pass_fee_to_client: passFee,
                        },
                    },
                    'paylater'
                );
                await updateData();
                navigate(`/2`);
            } else {
                throw new Error('Missing auth token, try refreshing the page.');
            }
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't update your information right now, try refreshing the page later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({
                ...currentState,
                plIsLoading: false,
            }));
        }
    };

    const mapValuesToLabels = values => {
        return values.map(v => ({
            value: v,
            label: `${v} days`,
        }));
    };

    const handleOverlayClose = () => {
        navigate(`/2`);
    };

    return plIsLoading ? (
        <Loader />
    ) : (
        <Overlay title="Agree and activate" handleClose={handleOverlayClose}>
            <OverlayFieldset subtitle="What you'll get">
                <OverlayKeypoint summary="Payment upfront*" details={'100%'} />
            </OverlayFieldset>
            <OverlayFieldset subtitle="How your customers will pay">
                <OverlayKeypoint
                    summary="Longest payment period for your customers"
                    details={`${
                        data.paylater?.approval?.options?.max_period?.slice(-1)[0] ?? 60
                    } days`}
                />
                <OverlayKeypoint
                    summary="Fee per transaction**"
                    details={percentageFormatter(data.paylater?.approval?.fees ?? '1.00%')}
                />
            </OverlayFieldset>
            {/* <OverlayFieldset subtitle="What you'll select">
                <OverlayOption
                    summary="Longest payment period for your customers*"
                    min={data.paylater?.approval?.options?.max_period[0] ?? 15}
                    max={data.paylater?.approval?.options?.max_period?.slice(-1)[0] ?? 60}
                    marks={mapValuesToLabels(
                        data.paylater?.approval?.options?.max_period ?? [15, 30, 45, 60]
                    )}
                    value={maxPeriod}
                    handleChange={(e, newValue) => setMaxPeriod(newValue)}
                />
                <OverlaySwitch
                    summary="I want to pass the fee to my customers**"
                    checked={passFee}
                    onChange={e => setPassFee(e.target.checked)}
                    name="passFee"
                    labels={['Yes', 'No']}
                />
            </OverlayFieldset> */}
            <Terms
                termsToSign={{
                    text: `I agree to the PayLater Terms and Services and authorize that I am a controlling member of ${
                        data.company?.name ?? 'the company'
                    }`,
                    url: 'https://home.golendica.com/agreement-paylater',
                }}
                checked={agreeTerms}
                onChange={e => setAgreeTerms(e.target.checked)}
            />
            <FootNote
                lines={[
                    '*You will receive 100% of the invoice upfront and the rest as your customer pays.',
                    '**Your customer will pay an additional 1% spread over their weekly payments.',
                ]}
            />
            <OverlayAction action="Activate" disabled={!agreeTerms} onClick={handleSubmit} />
        </Overlay>
    );
};
