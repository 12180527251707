import React from 'react';
import {
    Typography,
    List,
    ListSubheader,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Divider,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    divider: {
        '&:last-child': {
            display: 'none',
            visibility: 'hidden',
        },
    },
}));

export const SummaryListItem = ({ label, value }) => {
    return (
        <ListItem disableGutters>
            <ListItemText primary={label} />
            <ListItemSecondaryAction>
                <Typography variant="caption" color="textSecondary">
                    {value}
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export const SummaryList = ({ title = 'Summary', data, ...rest }) => {
    const classes = useStyles();
    return (
        <List
            {...rest}
            sx={{ bgcolor: 'inherit' }}
            subheader={
                <ListSubheader sx={{ bgcolor: 'inherit' }} disableGutters>
                    {title}
                </ListSubheader>
            }
        >
            {data.map((item, index) => (
                <React.Fragment key={index}>
                    <SummaryListItem {...item} />
                    <Divider className={classes.divider} />
                </React.Fragment>
            ))}
        </List>
    );
};
