export class InvoiceCache {
    #loaded = false;
    #invoices;
    #activated;
    #promises = new Map();
    #onChange = new Set();
    #onLoad = new Set();

    getById(id) {
        if (this.#activated === false) {
            return { id, activated: false };
        }
        if (this.#invoices) {
            return this.#invoices.get(id);
        }
        const promiseObj = this.#promises.get(id);
        if (promiseObj) {
            return promiseObj.promise;
        }
        let resolver;
        const newPromise = new Promise(res => {
            resolver = res;
        });

        this.#promises.set(id, {
            promise: newPromise,
            resolver,
            id,
        });
        return newPromise;
    }

    set activated(value) {
        if (this.#activated === value || this.#activated) {
            return;
        }
        let loaded = this.#loaded;
        this.#loaded = true;
        this.#activated = value;
        if (!value) {
            this.#promises.forEach(promiseObj => {
                promiseObj.resolver({ id: promiseObj.id, activated: false });
            });
            this.#promises.clear();
            setTimeout(() => {
                if (loaded) {
                    [...this.#onChange.values()].forEach(subscriber => {
                        subscriber();
                    });
                } else {
                    [...this.#onLoad.values()].forEach(subscriber => {
                        subscriber();
                    });
                }
            }, 0);
        }
    }

    set invoices(invoices) {
        let loaded = this.#loaded;
        this.#loaded = true;
        this.#invoices = new Map(invoices.map(invoice => [invoice.order_id, invoice]));
        this.#promises.forEach(promiseObj => {
            promiseObj.resolver(this.#invoices.get(promiseObj.id));
        });
        this.#promises.clear();
        setTimeout(() => {
            if (loaded) {
                [...this.#onChange.values()].forEach(subscriber => {
                    subscriber();
                });
            } else {
                [...this.#onLoad.values()].forEach(subscriber => {
                    subscriber();
                });
            }
        }, 0);
    }

    onLoad(subscriber) {
        this.#onLoad.add(subscriber);
        return () => this.#onLoad.delete(subscriber);
    }

    onChange(subscriber) {
        this.#onChange.add(subscriber);
        return () => this.#onChange.delete(subscriber);
    }

    clear() {
        this.#invoices?.clear();
        this.#promises.clear();
        this.#onChange.clear();
    }
}

export const invoiceCache = new InvoiceCache();
