import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { AppContext, DataContext } from './AppContext';
import { Sidebar } from './Sidebar';
import { SideButton } from './SideButton';
import { getTheme } from './theme';

import { createInstance, initUser } from './api';
// import { defaultInit } from './data';
// import { isAllVOD, isVOD } from './utils';
import { invoiceCache } from './invoiceCache';
import PayLaterPopup from './Sidebar/components/PayLaterPopup';

export function updateInvoiceCache({ invoices, activated }) {
    invoiceCache.activated = activated;
    if (activated) {
        invoiceCache.invoices = invoices;
    }
}

export const App = ({ root, credentials, config }) => {
    const [appState, setAppState] = useState({
        isOpen: false,
        appIsLoading: false,
        fnIsLoading: false,
        plIsLoading: false,
        invoiceIsLoading: false,
        lastRefresh: null,
        snackbar: {
            open: false,
            message: '',
        },
        isHeaderVisible: true,
        userAccessType: config?.access_type?.toUpperCase() ?? 'ADMIN',
        environment: config?.environment?.toUpperCase() ?? 'SANDBOX',
        credentials: credentials ?? null,
        applyOnComplete: null,
        // isPopupOpen: false,
        // popupUrl: null,
    });
    const [popupState, setPopupState] = useState({ open: false, url: null, onComplete: null });
    const navigate = useNavigate();

    const [data, setData] = useState();

    useEffect(() => {
        function open(e) {
            setAppState(currentState => ({
                ...currentState,
                isOpen: true,
            }));
            if (e.detail?.invoiceId) {
                navigate(`/1/invoice/${e.detail.invoiceId}`);
            }
        }
        function apply(e) {
            setAppState(currentState => ({
                ...currentState,
                isOpen: true,
                applyOnComplete: e.detail?.onComplete ?? null,
            }));
            navigate('/paylater/apply');
        }
        function openPopup(e) {
            setPopupState({ open: true, url: e.detail.url, onComplete: e.detail.onComplete });
        }
        root.addEventListener('open-widget', open);
        root.addEventListener('open-popup', openPopup);
        root.addEventListener('apply', apply);
        return () => {
            root.removeEventListener('open-widget', open);
            root.removeEventListener('open-popup', openPopup);
            root.removeEventListener('apply', apply);
        };
    }, [setAppState]);

    const updateData = async () => {
        const newInitData = await initUser(credentials);
        if (!newInitData?.products?.fundnow && !newInitData?.products?.paylater) {
            setData(currentState => ({
                ...currentState,
                company: newInitData.company,
                products: newInitData.products,
            }));
            setAppState(currentState => ({
                ...currentState,
                credentials: { ...currentState.credentials, ...newInitData.company },
            }));
            return;
        }
        if (newInitData?.products?.fundnow || newInitData?.products?.paylater) {
            updateInvoiceCache(newInitData.fundnow);
            setData(currentState => ({
                ...currentState,
                ...newInitData,
            }));
            setAppState(currentState => ({
                ...currentState,
                lastRefresh: Date(),
                credentials: { ...currentState.credentials, ...newInitData.company },
            }));
        } else {
            throw new Error('Invalid data returned.');
        }
    };

    useEffect(() => {
        (async function () {
            try {
                await createInstance(config?.environment?.toUpperCase() ?? 'SANDBOX');
                setAppState(currentState => ({
                    ...currentState,
                    appIsLoading: true,
                    // fnIsLoading: true,
                    // plIsLoading: true,
                }));
                await updateData();
            } catch (error) {
                setAppState(currentState => ({
                    ...currentState,
                    snackbar: {
                        open: true,
                        message: `We have trouble connecting to the server. If you have activated already, please try refreshing the page later. ${error}`,
                    },
                }));
            } finally {
                setAppState(currentState => ({
                    ...currentState,
                    appIsLoading: false,
                    // fnIsLoading: false,
                    // plIsLoading: false,
                }));
            }
        })();
        hintEnv(appState.environment);
    }, []);

    const theme = useMemo(() => getTheme(), []);

    const hintEnv = env => {
        if (/SANDBOX/gi.test(env)) {
            console.info(
                `%c Lendica `,
                'background: #0E0E2C; color: #FCFCFD',
                `You're now in sandbox mode. For production, pass the environment as 'PRODUCTION' (case-insensitive) in Lendica config.`
            );
        }
        if (/DEV/gi.test(env)) {
            console.info(
                `%c Lendica `,
                'background: #0E0E2C; color: #FCFCFD',
                `Internal development mode. If you're seeing this message, please contact Lendica for support.`
            );
        }
    };

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            localStorage.setItem('appState', JSON.stringify(appState));
        }, [appState]);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <AppContext.Provider
                    value={{
                        appState,
                        setAppState,
                        toggleIsOpen: () => {
                            setAppState(currentState => ({
                                ...currentState,
                                isOpen: !currentState.isOpen,
                            }));
                        },
                        toggleHeaderVisibility: () => {
                            setAppState(currentState => ({
                                ...currentState,
                                isHeaderVisible: !currentState.isHeaderVisible,
                            }));
                        },
                        setError(message) {
                            setAppState(currentState => ({
                                ...currentState,
                                snackbar: {
                                    open: true,
                                    message,
                                },
                            }));
                        },
                        updateData,
                    }}
                >
                    <DataContext.Provider value={[data, setData]}>
                        <SideButton />
                        <Sidebar />
                        <PayLaterPopup
                            url={popupState.url}
                            isOpen={popupState.open}
                            onComplete={() => popupState.onComplete()}
                            closeHandler={() =>
                                setPopupState(prevState => ({ ...prevState, open: false }))
                            }
                        />
                    </DataContext.Provider>
                </AppContext.Provider>
            </ThemeProvider>
        </LocalizationProvider>
    );
};
