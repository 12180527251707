import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, Box, useMediaQuery } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { LinkButton } from '@lendica/components';

import {
    Guide,
    GuideAction,
    GuideStep,
    SidebarTable,
    ConsentPopover,
    Footer,
    ScrollArea,
} from '../../components';
import { PLPreHeader } from './PLPreHeader';

import { settings } from '../../../settings';
import { useDataContext, useAppContext } from '../../../AppContext';
import { activateOffer } from '../../../api';
import { useTableData } from '../../../utils';

import { defaultInit } from '../../../data';

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 30,
        boxShadow: 'none',
    },
}));

export const PayLaterPre = ({ containerRef, headerRef }) => {
    const {
        appState: { userAccessType },
        setAppState,
    } = useAppContext();
    const [data, setData] = useDataContext();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const scrollToRef = useRef();

    const match = useMediaQuery(settings.stickyHeaderMediaQuery);

    const tableData = useTableData(
        'paylater',
        data?.paylater?.invoices ?? defaultInit.paylater.invoices
    );

    const handleActivate = async () => {
        try {
            if (data?.company?.lendica_token) {
                setAppState(currentState => ({ ...currentState, plIsLoading: true }));
                const approvalData = await activateOffer(data?.company?.lendica_token, 'paylater');
                setData(currentState => ({
                    ...currentState,
                    paylater: { ...currentState.paylater, ...approvalData },
                }));
                navigate('offer');
            } else {
                throw new Error(
                    'Missing auth token, try refreshing the page or check your network connection.'
                );
            }
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't load the agreement. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({
                ...currentState,
                plIsLoading: false,
            }));
        }
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: '1 0 auto' }}>
                <PLPreHeader handleActivate={handleActivate} />
                <Box mt={4}>
                    {tableData && (
                        <Box px={2}>
                            <SidebarTable data={tableData} limit={4} gradient />
                        </Box>
                    )}
                    <Box mt={tableData ? 4 : 0} px={2} sx={{ width: '70%' }}>
                        <Typography variant="h5" color="textPrimary" component="h5">
                            Activate now to allow your customers to pay over time.
                        </Typography>
                    </Box>
                    <Grid justifyContent="space-between" container component={Box} mt={4} px={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={handleClick}
                                disabled={userAccessType === 'VIEW_ONLY'}
                            >
                                Activate
                            </Button>
                        </Grid>
                        <Grid item>
                            <ScrollArea
                                title="See How it Works"
                                to={scrollToRef}
                                offset={match ? headerRef : undefined}
                                container={containerRef}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={8} px={2}>
                        <Grid justifyContent="flex-end" container component={Box} ref={scrollToRef}>
                            <Box sx={{ width: '70%', textAlign: 'right' }}>
                                <Typography variant="h5" color="textPrimary" component="h5">
                                    Your customers want to pay later, but you need cash now.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid justifyContent="center" container component={Box} mt={2}>
                            <img
                                src="https://static.golendica.com/paylater_mkt-OF4HWL5R.png"
                                width="85%"
                                alt="paylater example screens"
                            />
                        </Grid>
                    </Box>
                    <Box mt={8} px={2}>
                        <Guide title="How it works">
                            <GuideStep
                                stepNumber="1"
                                title="Activate PayLater"
                                body={
                                    <>
                                        Provide basic information about your business and accept the
                                        Merchant Agreement.{' '}
                                        {userAccessType === 'ADMIN' && (
                                            <LinkButton
                                                variant="body2"
                                                color="secondary"
                                                onClick={handleClick}
                                            >
                                                Activate Now
                                            </LinkButton>
                                        )}
                                    </>
                                }
                            />
                            <GuideStep
                                stepNumber="2"
                                title="Invite Customers"
                                body="Choose which customers you would like to offer a PayLater option. After entering some basic information, your customers can select their preferred payment terms."
                            />
                            <GuideStep
                                stepNumber="3"
                                title="Get Paid on Delivery"
                                body="Collect 50% of the invoice value on delivery and receive the rest, minus a simple, flat fee over the next two to six weeks."
                            />
                            <GuideAction
                                title="Activate"
                                onClick={handleClick}
                                className={classes.button}
                                disabled={userAccessType === 'VIEW_ONLY'}
                            />
                        </Guide>
                    </Box>
                    <Box mt={8} px={2}>
                        <Typography variant="h5" color="textPrimary" component="h5">
                            Questions?
                        </Typography>
                        <Grid container justifyContent="space-between" component={Box} mt={2}>
                            <Grid item>
                                <Typography variant="h6" color="textPrimary">
                                    Schedule a meeting with us
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    href="https://calendly.com/lendica/intro"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Schedule
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* <Box mt={8} px={2}>
                        <Typography variant="caption">
                            *By clicking "ACTIVATE" or "INVITE ALL" you hereby consent to share data
                            with Lendica Corp. to assist in providing lending related services.
                        </Typography>
                    </Box> */}
                </Box>
            </Box>
            <ConsentPopover
                anchorEl={anchorEl}
                content={`By proceeding you hereby consent to share data with Lendica Corp. to assist in providing lending related services.`}
                handleClose={handleClose}
                handleConsent={handleActivate}
            />
            <Box sx={{ flexShrink: 0 }} mt={6}>
                <Footer />
            </Box>
        </Box>
    );
};
