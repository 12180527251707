import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from '@lendica/loan-form';

import { useAppContext, useDataContext } from '../AppContext';
import { activateOffer } from '../api';
import { getBackendURL } from '@lendica/utils';

const applicationStatuses = {
    inProgress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

export const ApplicationForm = () => {
    const {
        appState: { environment },
        toggleHeaderVisibility,
        setAppState,
    } = useAppContext();
    const { applicationId } = useParams();
    const [data, setData] = useDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        toggleHeaderVisibility();
        return () => toggleHeaderVisibility();
    }, []);

    const getOffer = async () => {
        try {
            setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
            if (data?.company?.lendica_token) {
                const approvalData = await activateOffer(data?.company?.lendica_token, 'fundnow');
                setData(currentState => ({
                    ...currentState,
                    fundnow: { ...currentState.fundnow, ...approvalData },
                }));
                navigate('/1/offer');
            } else {
                throw new Error('Missing auth token.');
            }
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `There was an error loading the agreement, please try again later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({ ...currentState, fnIsLoading: false }));
        }
    };

    const handleFormComplete = ({ application_status }) => {
        if (application_status === applicationStatuses.approved) {
            navigate('/1');
            getOffer();
            return;
        }
        navigate('/1/submitted');
    };

    return (
        <Form
            applicationId={applicationId}
            baseURL={getBackendURL(environment)}
            onExit={() => {
                navigate('/1');
            }}
            onComplete={handleFormComplete}
        />
    );
};
