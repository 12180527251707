import { useState, useRef, useEffect } from 'react';

const isToday = someDate => {
    const today = new Date();
    const date = new Date(someDate);
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

const isSelectedDate = (selectedDate, someDate) => {
    const today = new Date(selectedDate);
    const date = new Date(someDate);
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

const notZero = n => {
    n = +n;
    if (!n) {
        throw new Error('Invalid dividend ' + n);
    }
    return n;
};

export const usePayment = ({ activities, summary, live_withdrawals, listRef }) => {
    const [schedules, setSchedules] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [livePaid, setLivePaid] = useState(0);

    const pageRef = useRef(null);
    const progressRef = useRef(null);
    const calendarRef = useRef(null);
    const schedulesRef = useRef(null);

    const progress = () => {
        try {
            // return Math.round(
            //     (summary.total_paid / notZero(summary.total_paid + summary.total_unpaid)) * 100
            // );
            return Math.round((livePaid / notZero(livePaid + summary?.total_unpaid)) * 100);
        } catch (e) {
            return 0;
        }
    };

    useEffect(() => {
        setLivePaid(
            live_withdrawals?.reduce((acc, curr) => acc + curr.withdrawal_amount_paid, 0) ?? 0
        );
        setSchedules(activities?.filter(item => item.activity_type === 1 && item.status === 0));
    }, []);

    // const dueToday = activities.find(
    //     item => item.activity_type === 1 && item.status === 0 && isToday(item.activity_date)
    // );

    const dueTodayTotal = () => {
        let total = 0;
        activities.forEach(item => {
            if (item.activity_type === 1 && item.status === 0 && isToday(item.activity_date)) {
                total += item.activity_amount;
            }
        });
        return total;
    };

    const nextDue = () => {
        const payments = schedules.sort(
            (a, b) => new Date(a.activity_date) - new Date(b.activity_date)
        );
        const first = payments[0];

        if (!first) {
            return 'N/A';
        }

        return {
            date: new Date(first.activity_date)
                .toLocaleString('en-us', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit',
                })
                .replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3'),
            amount: first.activity_amount,
        };
    };

    const lastPayment = () => {
        const payments = schedules.sort(
            (a, b) => new Date(a.activity_date) - new Date(b.activity_date)
        );
        const last = payments[payments.length - 1];

        if (!last) {
            return 'N/A';
        }

        return new Date(last.activity_date)
            .toLocaleString('en-us', {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3');
    };

    const getActiveTabRef = value => {
        const refs = {
            0: progressRef,
            1: calendarRef,
            2: schedulesRef,
        };

        return refs[value];
    };

    const getSelectedDateSchedules = () => {
        return schedules.filter(item => isSelectedDate(selectedDate, item.activity_date));
    };

    const executeScroll = (container, to, offset) =>
        container.current.scroll({
            top: to.current.offsetTop - (offset ? offset.current.offsetHeight : 0),
            behavior: 'smooth',
        });

    const handleTabChange = (event, newValue) => {
        const ref = getActiveTabRef(newValue);

        setActiveTab(newValue);
        executeScroll(pageRef, ref);
    };

    const onScroll = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
        const topIndex = scrollOffset / 64;

        if (schedules.length - topIndex < (schedulesRef.current.clientHeight + 30) / 64)
            setShowAll(false);
        else setShowAll(true);
    };

    const handleMore = () => {
        // setShowAll(false);
        // if (listRef.current) {
        const topIndex = (listRef.current.state.scrollOffset / 64).toFixed();
        if (topIndex < schedules.length - 4) listRef.current.scrollToItem(+topIndex + 4, 'start');
        else listRef.current.scrollToItem(schedules.length - 1, 'start');
        // }
    };

    return {
        showAll,
        activeTab,
        livePaid,
        progress: progress(),
        dueToday: dueTodayTotal(),
        nextDue: nextDue(),
        lastPayment: lastPayment(),
        schedules,
        selectedDate,
        selectedDateSchedules: getSelectedDateSchedules(),
        pageRef,
        progressRef,
        calendarRef,
        schedulesRef,
        onScroll,
        handleMore,
        handleTabChange,
        handleDateChange,
    };
};
