import { useContext } from 'react';
import { useTheme, useMediaQuery, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Transition } from 'react-transition-group';

import { Logo } from '@lendica/components';

import { AppContext } from './AppContext';
import { settings } from './settings';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: settings.buttonTop,
        right: 0,
        transform: 'rotate(-90deg)',
        transformOrigin: 'bottom right',
        zIndex: theme.zIndex.drawer,
    },
    button: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
    },
}));

export const SideButton = () => {
    const {
        appState: { isOpen },
        toggleIsOpen,
    } = useContext(AppContext);

    const classes = useStyles(isOpen);

    const theme = useTheme();

    const transformEnter = `rotate(-90deg) translateY(-${settings.sidebarWidth}px)`;
    const transitionEnter = `transform ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut} 0ms`;
    const transformExit = 'rotate(-90deg)';
    const transitionExit = `transform ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp} 0ms`;

    const matches = useMediaQuery(settings.fullscreenMediaQuery(theme));

    const transitionStyles = {
        entering: {
            transform: transformEnter,
            transition: transitionEnter,
        },
        entered: {
            transform: transformEnter,
            transition: transitionEnter,
            zIndex: theme.zIndex.drawer,
        },
        exiting: {
            transform: transformExit,
            transition: transitionExit,
        },
        exited: {
            transform: transformExit,
            transition: transitionExit,
        },
    };

    return (
        <Transition
            in={isOpen && !matches}
            timeout={{
                enter: theme.transitions.duration.enteringScreen,
                exit: theme.transitions.duration.leavingScreen,
            }}
        >
            {state => (
                <div className={classes.root} style={transitionStyles[state]}>
                    <Button
                        className={classes.button}
                        id="btn-sidebar-lendica"
                        variant="contained"
                        color="primary"
                        endIcon={isOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                        onClick={() => {
                            toggleIsOpen();
                        }}
                    >
                        <Logo dark={false} height={18} />
                    </Button>
                </div>
            )}
        </Transition>
    );
};
