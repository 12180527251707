import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';

import { useAppContext, useDataContext } from '../../../AppContext';
import { SidebarTable } from '../../components/SidebarTable';
import { Footer } from '../../components/Footer';
import { PLPostHeader } from './PLPostHeader';

import { useTableData } from '../../../utils';

export const PayLaterPost = () => {
    const {
        appState: { invoiceIsLoading },
    } = useAppContext();
    const [
        {
            paylater: { invoices },
        },
    ] = useDataContext();

    const navigate = useNavigate();

    const handleTableAction = order_id => event => {
        navigate(`/2/invoice/${order_id}`);
    };

    const tableData = useTableData('paylater', invoices, handleTableAction);

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: '1 0 auto' }}>
                <PLPostHeader />
                <Box mt={4}>
                    <Grid
                        justifyContent="flex-start"
                        container
                        component={Box}
                        mt={2}
                        mb={2}
                        px={2}
                    >
                        <Box sx={{ width: '70%', textAlign: 'left' }}>
                            <Typography variant="h5" color="textPrimary" component="h5">
                                My Invoices
                            </Typography>
                        </Box>
                    </Grid>
                    {tableData && (
                        <Box px={2}>
                            <SidebarTable data={tableData} loading={invoiceIsLoading} />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={{ flexShrink: 0 }} mt={8}>
                <Footer />
            </Box>
        </Box>
    );
};
