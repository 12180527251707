import { createContext, useContext } from 'react';

export const AppContext = createContext();
export const DataContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
};

export const useDataContext = () => {
    return useContext(DataContext);
};
