import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Box, Button, Typography, List, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FeatureHeader, Toolbar, DrawDownLogo } from '@lendica/components';

import { useAppContext } from '../../../../AppContext';
import { DrawDownContainer } from '../DrawDownContainer';
import { usePayment } from './Payment/hooks/usePayment';
import { Header, Body, Footer, ConsentPopover } from '../../../components';
import { PaymentWidget } from './Payment/PaymentWidget';
import { WithdrawalWidget } from './Withdrawal/WithdrawalWidget';
import { PushMsg } from '../../../components/PushMsg';
import { ActivityItem } from './Activities/ActivityItem';
import { Marketing } from './Marketing/Marketing';

const useStyles = makeStyles(theme => ({
    // productName: {
    //     color: theme.palette.background.paper,
    //     backgroundColor: theme.palette.primary.light,
    //     borderRadius: 2,
    // },
    card: {
        borderRadius: 12,
        borderColor: theme.palette.background.cardBorder,
        borderWidth: 1,
        backgroundColor: theme.palette.background.cardLight,
        borderStyle: 'solid',
    },
    underline: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.background.cardBorder,
    },
    divider: {
        'marginRight': theme.spacing(2),
        '&:last-child': {
            display: 'none',
            visibility: 'hidden',
        },
    },
    button: {
        borderRadius: 30,
        boxShadow: 'none',
        width: '100%',
    },
    alert: {
        marginBottom: theme.spacing(3),
        background:
            "center / cover url('https://lendicapublic.s3.amazonaws.com/assets/illustrations/unsplash_ZKVBM2_Dp84.png')",
        boxShadow: 'inset 0 0 0 1000px rgba(255,255,255,.7)',
    },
}));

const pushMsg = {
    title: 'Cash Conversion. Solved.',
    content:
        'Did you know that Lendica’s PayLater product can delay vendor payments and dramatically increase your cash conversion? We’ll show you how.',
    to: 'https://calendly.com/lendica/intro',
};

export const Main = () => {
    const [pushMsgOpen, setPushMsgOpen] = useState(true);
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        appState: { userAccessType },
        setAppState,
    } = useAppContext();
    const { data, activateApply } = DrawDownContainer.useContainer();
    const { nextDue } = usePayment({
        approval: data?.approval,
        activities: data?.activities,
        summary: data?.summary,
    });
    const headerRef = useRef();
    const containerRef = useRef();

    const handleClose = () => {
        setPushMsgOpen(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleConsentClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleConsentClose = () => {
        setAnchorEl(null);
    };

    const openForm = async () => {
        try {
            const responseData = await activateApply();
            navigate(`/3/application/${responseData?.application_id}`);
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `${error}`,
                },
            }));
        }
    };

    return (
        <>
            <Header
                rightActions={
                    <IconButton size="small" onClick={() => navigate('/settings')}>
                        <MoreVertIcon />
                    </IconButton>
                }
                ref={headerRef}
            />
            <Body sx={{ height: '100%' }} display="flex" flexDirection="column" ref={containerRef}>
                <Box sx={{ flex: '1 0 auto' }} p={2}>
                    <FeatureHeader
                        name={<DrawDownLogo />}
                        available={data?.summary?.credit_available ?? 0}
                        total={data?.summary?.credit_line_total ?? 0}
                        unpaid={data?.summary?.total_unpaid ?? 0}
                        entityNumber={Object.keys(data?.live_withdrawals)?.length ?? 0}
                        entity="withdrawals"
                    />
                    <WithdrawalWidget
                        available={data?.summary?.credit_available ?? 0}
                        gradient={
                            data?.approval &&
                            Object.keys(data?.approval).length > 0 &&
                            userAccessType === 'ADMIN'
                                ? false
                                : true
                        }
                    />
                    {data?.approval &&
                    Object.keys(data?.approval).length > 0 &&
                    userAccessType === 'ADMIN' ? (
                        <>
                            <PaymentWidget
                                payment={nextDue?.amount ?? 0}
                                due={nextDue?.date ?? ''}
                                expire={data?.summary?.credit_days_remaining ?? 0}
                            />
                            <PushMsg
                                data={pushMsg}
                                open={pushMsgOpen}
                                onClose={handleClose}
                                classes={{ root: classes.alert }}
                            />
                            {data?.activities && data?.activities?.length > 0 && (
                                <>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb={2}
                                    >
                                        <Typography variant="h5" color="textPrimary">
                                            Latest Activities
                                        </Typography>
                                        <Button onClick={() => navigate('activities')} size="small">
                                            See all
                                        </Button>
                                    </Box>
                                    <List component="nav" className={classes.card} px={2} py={1}>
                                        {data.activities
                                            .filter(d => !(d.activity_type === 1 && d.status === 0))
                                            .reverse()
                                            .slice(0, 4)
                                            .map((activity, index) => (
                                                <React.Fragment key={index}>
                                                    <ActivityItem activity={activity} />
                                                    <Divider
                                                        variant="inset"
                                                        component="li"
                                                        className={classes.divider}
                                                    />
                                                </React.Fragment>
                                            ))}
                                    </List>
                                </>
                            )}
                        </>
                    ) : (
                        <Marketing
                            setAnchorEl={setAnchorEl}
                            headerRef={headerRef}
                            containerRef={containerRef}
                        />
                    )}
                </Box>
                <Box sx={{ flexShrink: 0 }} mt={2}>
                    <Footer />
                </Box>
            </Body>
            {(data?.approval && Object.keys(data?.approval).length > 0) ||
            userAccessType === 'VIEW_ONLY' ? null : (
                <>
                    <Toolbar
                        action={
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                className={classes.button}
                                onClick={handleConsentClick}
                            >
                                Activate Now
                            </Button>
                        }
                    />
                    <ConsentPopover
                        anchorEl={anchorEl}
                        content={`By proceeding you hereby consent to share data with Lendica Corp. to assist in providing lending related services.`}
                        handleClose={handleConsentClose}
                        handleConsent={openForm}
                    />
                </>
            )}
        </>
    );
};
