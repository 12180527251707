import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from '@lendica/loan-form';
import { Header, Body } from '../../../../components';

import { useAppContext } from '../../../../../AppContext';
import { DrawDownContainer } from '../../DrawDownContainer';
import { useApi } from '../../api';

const applicationStatuses = {
    inProgress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

export const Application = () => {
    const {
        appState: { environment },
        toggleHeaderVisibility,
        setAppState,
    } = useAppContext();
    const { applicationId } = useParams();
    const { activateOffer, updateDrawDown } = DrawDownContainer.useContainer();
    const api = useApi(environment);

    const navigate = useNavigate();

    useEffect(() => {
        toggleHeaderVisibility();
        return () => toggleHeaderVisibility();
    }, []);

    const getOffer = async () => {
        try {
            await activateOffer();
            updateDrawDown();
            navigate(`/3`);
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `${error}`,
                },
            }));
        }
    };

    const handleFormComplete = ({ application_status }) => {
        if (application_status === applicationStatuses.approved) {
            getOffer();
            return;
        }
        navigate('/3/submitted');
    };

    return (
        <>
            <Header />
            <Body>
                <Form
                    applicationId={applicationId}
                    baseURL={api.baseUrl}
                    onExit={() => {
                        navigate('/3');
                    }}
                    onComplete={handleFormComplete}
                />
            </Body>
        </>
    );
};
