import { Typography, Box, Popover, Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    popover: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
    },
}));

export const ConsentPopover = ({
    anchorEl,
    content,
    handleClose,
    handleConsent,
    actionCancelId = '',
    actionProceedId = '',
}) => {
    const classes = useStyles();

    const open = Boolean(anchorEl);
    const id = open ? 'popover-consent' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            PaperProps={{
                style: {
                    width: '40ch',
                },
            }}
        >
            <Box py={2} px={3} className={classes.popover}>
                <Typography variant="subtitle2" color="textSecondary" paragraph>
                    {content}
                </Typography>
                <Box>
                    <Button size="small" color="primary" id={actionCancelId} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        color="secondary"
                        id={actionProceedId}
                        onClick={handleConsent}
                    >
                        Ok
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
};
