import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDataContext } from '../../../AppContext';
import { Header, Body } from '../../components';
import { SettingsListGroup } from './SettingsList';
import { useData } from './useSettings';
// import { DrawDownContainer } from '../DrawDown/DrawDownContainer';
import { currencyFormatter } from '@lendica/utils';

const getData = (navigate, invoiceData) => {
    return [
        {
            subheader: 'Profile',
            listData: [
                {
                    text: 'Company Name',
                    details: invoiceData?.company?.name ?? '',
                    disableAction: true,
                },
                {
                    text: 'Email',
                    details: invoiceData?.company?.company_email ?? '',
                    disableAction: true,
                },
                // {
                //     text: 'Contact Info',
                //     details: 'Edit',
                //     onClick: () => navigate('/settings/contact'),
                // },
            ],
        },
        // {
        //     subheader: 'Products',
        //     listData: [
        //         invoiceData?.products?.fundnow && {
        //             text: 'FundNow',
        //             details: invoiceData?.fundnow?.activated
        //                 ? currencyFormatter(invoiceData?.fundnow?.summary?.funding_credit_left) ??
        //                   ''
        //                 : 'Activate now',
        //             onClick: () => navigate('/1'),
        //         },
        //         invoiceData?.products?.paylater && {
        //             text: 'PayLater',
        //             details: invoiceData?.paylater?.activated
        //                 ? currencyFormatter(invoiceData?.paylater?.summary?.invoice_total) ?? ''
        //                 : 'Activate now',
        //             onClick: () => navigate('/2'),
        //         },
        //         invoiceData?.products?.drawdown && {
        //             text: 'DrawDown',
        //             details: 'See details',
        //             // details: currencyFormatter(cashApproval?.remaining_balance) ?? '',
        //             onClick: () => navigate('/3'),
        //         },
        //     ],
        // },
        {
            subheader: 'Help',
            listData: [
                {
                    text: 'Contact Us',
                    onClick: () => window.open('https://home.golendica.com/#contact'),
                },
                {
                    text: 'Schedule an Appointment',
                    onClick: () => window.open('https://calendly.com/lendica/intro'),
                },
            ],
        },
        {
            subheader: '',
            listData: [
                {
                    text: 'Version',
                    details: 'v1.1.0 \u00a9 Lendica Corp., May 2022',
                    disableAction: true,
                },
            ],
        },
    ];
};

export const Settings = () => {
    const navigate = useNavigate();
    // const {
    //     data: { approval },
    // } = DrawDownContainer.useContainer();
    const [data] = useDataContext();
    const settingsData = useData(getData, navigate, data);

    return (
        <>
            <Header
                title="Settings"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate(-1)}>
                        <CloseIcon size="small" />
                    </IconButton>
                }
            />
            <Body display="flex" flexDirection="column">
                {settingsData &&
                    settingsData.length > 0 &&
                    settingsData.map((item, index) => (
                        <Box key={`group-${index}`} mt={4}>
                            <SettingsListGroup {...item} />
                        </Box>
                    ))}
                {/* <FootNote lines={[`v1.0.0 \u00a9 Lendica Corp., Dec 2021`]} /> */}
            </Body>
        </>
    );
};
