import { useAppContext, useDataContext } from '../../../AppContext';
import { Loader } from '../../components';

import { FundNowPre } from './FundNowPre';
import { FundNowPost } from './FundNowPost';

export const FundNow = ({ containerRef, headerRef }) => {
    const {
        appState: { fnIsLoading, userAccessType },
    } = useAppContext();

    const [data] = useDataContext();

    return fnIsLoading ? (
        <Loader />
    ) : data?.fundnow?.activated && userAccessType === 'ADMIN' ? (
        <FundNowPost />
    ) : (
        <FundNowPre containerRef={containerRef} headerRef={headerRef} />
    );
};
