import { Box, Typography, Grid, Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const Guide = ({ title, children }) => {
    return (
        <Box>
            <Typography variant="h5" color="textPrimary" component="h5">
                {title}
            </Typography>
            <Box pt={3}>{children}</Box>
        </Box>
    );
};

export const GuideAction = ({ title, actionId = '', onClick, disabled, className }) => {
    return (
        <Grid container justifyContent="space-between" component={Box} py={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} component={Box} pt={1}>
                <Button
                    variant="contained"
                    color="secondary"
                    id={actionId}
                    className={className}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {title}
                </Button>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    circle: {
        width: 24,
        height: 24,
        backgroundColor: theme.palette.text.secondary,
        borderRadius: 12,
        color: theme.palette.background.default,
    },
    circleText: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export const GuideStep = ({ stepNumber, title, body }) => {
    const classes = useStyles();

    return (
        <Grid container justifyContent="space-between" alignItems="baseline" component={Box} py={1}>
            <Grid item xs={2}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.circle}
                >
                    <Typography variant="subtitle1" className={classes.circleText}>
                        {stepNumber}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="subtitle1" color="textPrimary" className={classes.circleText}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} component={Box}>
                <Typography variant="body2" color="textSecondary">
                    {body}
                </Typography>
            </Grid>
        </Grid>
    );
};
