import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Box, Button, Typography, Slider, InputBase, Chip, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { Header, Body } from '../../../../components';
import { Toolbar } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import NumberFormat from 'react-number-format';
import { WithdrawalBody } from './WithdrawalBody';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    amountButton: {
        'borderRadius': 20,
        'minWidth': 20,
        'padding': '3px 10px',
        'marginRight': theme.spacing(1),
        'height': 25,

        '& > span > p': {
            fontSize: 13,
        },
    },
    label: {
        'backgroundColor': '#757575',
        'paddingLeft': 15,
        'paddingRight': 15,
        'left': 'calc(-50% - 19px)',
        'borderRadius': 4,

        '& > span': {
            backgroundColor: 'inherit',
        },
        '& > span > span': {
            whiteSpace: 'nowrap',
        },
    },
    input: {
        '& > input': {
            fontSize: 40,
            color: theme.palette.secondary.main,
            fontWeight: 800,
        },
    },
    withdrawalButton: {
        width: '70%',
        height: 42,
        borderRadius: 30,
        boxShadow: 'none',
    },
    dollar: {
        fontWeight: 800,
        marginTop: 12,
    },
}));

export const Withdrawal = () => {
    const { data, withdrawalData, setWithdrawal } = DrawDownContainer.useContainer();
    const [values, setValues] = useState([1000, 2000, 5000, 10000]);
    const [amount, setAmount] = useState(0);
    const [marks, setMarks] = useState([]);
    const [width, setWidth] = useState(3);
    const navigate = useNavigate();
    const classes = useStyles();

    const available = parseFloat(data.approval.remaining_balance);
    const fee = data.approval.origination_fee_percentage * 100;
    const inputRef = useRef(null);

    useEffect(() => {
        let num = parseFloat(available);
        setValues([getRounded(0.1 * num), getRounded(0.2 * num), getRounded(0.4 * num), num]);
        setMarks(
            [...Array(11).keys()].map(item => {
                const markValue = getRounded((num * item) / 10);
                if (num - markValue < num * 0.1) {
                    return { value: num };
                }
                return { value: markValue };
            })
        );
    }, [available]);

    useEffect(() => {
        const fee = data?.approval?.origination_fee_percentage ?? 0.05;
        const maxRate = Math.max.apply(null, Object.values(data?.approval?.factor_rates ?? 1.21));
        const maxTerm = Math.max.apply(null, Object.keys(data?.approval?.factor_rates ?? 180));
        setWithdrawal(!!amount ? amount : 0, fee, maxRate, maxTerm);
    }, [amount, data]);

    const getRounded = num => {
        if (num < 1000) return num;
        return Math.floor(num / 1000) * 1000;
    };

    const displayNumber = num => {
        if (num === parseFloat(available)) return 'Max';
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return num >= item.value;
            });
        return item ? (num / item.value).toFixed().replace(rx, '$1') + item.symbol : '0';
    };

    const handleChange = e => {
        const num =
            Math.abs(parseFloat(e.target.value.replace(/,/g, '')).toFixed(2)) > available
                ? available
                : Math.abs(parseFloat(e.target.value.replace(/,/g, '')).toFixed(2));
        if (!num || num === '' || isNaN(num) || num < 0.01) {
            setAmount(0);
            return;
        }
        setAmount(num);
    };
    const handleBlur = e => {
        const num = Math.abs(parseFloat(e.target.value.replace(/,/g, '')).toFixed(2));
        if (!num || num === '' || isNaN(num) || num < 0.01) setAmount(0);
    };
    const handlePlus = () => {
        const n = amount + 5000 <= parseFloat(available) ? amount + 5000 : parseFloat(available);
        setAmount(n);
    };
    const handleMinus = () => {
        const n = amount - 5000 >= 0 ? amount - 5000 : 0;
        setAmount(n);
    };
    const handleAmount = val => {
        setAmount(Math.abs(val));
    };

    const handleSlider = (evt, value) => {
        let realVal = value;
        marks.map(item => {
            if (Math.abs(item.value - realVal) < available / 40) {
                realVal = item.value;
            }
        });
        setAmount(realVal);
    };

    function valueLabelFormat(rate) {
        return `${rate} rate`;
    }

    return (
        <>
            <Header
                title="Withdrawal"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('/3')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
                rightActions={
                    <Button onClick={() => navigate('/3')} className={classes.button}>
                        Cancel
                    </Button>
                }
            />
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            maxWidth={235}
                            style={{ marginBottom: 27 }}
                        >
                            Choose Amount of Credit to Withdraw
                        </Typography>
                        <Typography variant="caption">
                            {currencyFormatter(available - amount)} credit left after withdrawal
                        </Typography>
                        <Stack direction="row" mt={1} spacing={1}>
                            {values.map((value, index) => (
                                <Chip
                                    key={Number(index) + 1}
                                    label={displayNumber(value)}
                                    clickable
                                    onClick={() => handleAmount(value)}
                                />
                            ))}
                        </Stack>
                    </Box>
                    <Box display="flex" mt={3} pr={1} mb={5}>
                        <IconButton onClick={handleMinus} size="large">
                            <RemoveCircleOutlineIcon fontSize="large" color="secondary" />
                        </IconButton>
                        <Box
                            flex="1"
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography
                                variant="h5"
                                color="secondary"
                                component={Box}
                                className={classes.dollar}
                            >
                                $
                            </Typography>
                            <NumberFormat
                                type="text"
                                displayType="input"
                                isAllowed={values => {
                                    const { floatValue } = values;
                                    return (
                                        !floatValue || (floatValue >= 0 && floatValue <= available)
                                    );
                                }}
                                onValueChange={values => {
                                    const { formattedValue } = values;
                                    const numericalLength =
                                        formattedValue.split(/[0-9]/g).length - 1;
                                    const halfLength = formattedValue.length - numericalLength;
                                    setWidth(numericalLength * 3 + halfLength * 0.5);
                                }}
                                defaultValue={0}
                                ref={inputRef}
                                thousandSeparator={true}
                                decimalSeparator="."
                                allowNegative={false}
                                isNumericString={true}
                                decimalScale={2}
                                customInput={InputBase}
                                fullWidth
                                value={amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.input}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' },
                                }}
                                style={{
                                    width: `${width}ch`,
                                }}
                            />
                        </Box>
                        <IconButton onClick={handlePlus} size="large">
                            <ControlPointIcon fontSize="large" color="secondary" />
                        </IconButton>
                    </Box>
                    <Slider
                        defaultValue={0}
                        valueLabelFormat={valueLabelFormat(withdrawalData.factor_rate)}
                        min={0}
                        max={available}
                        valueLabelDisplay="auto"
                        classes={{ valueLabel: classes.label }}
                        color="secondary"
                        value={amount}
                        onChange={handleSlider}
                        marks={marks}
                    />
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography variant="body2">0</Typography>
                        <Box textAlign="right">
                            <Typography variant="body2">{currencyFormatter(available)}</Typography>
                            <Typography variant="body2">Total Credit Available</Typography>
                        </Box>
                    </Box>
                    <WithdrawalBody showMore fee={fee} />
                </Box>
            </Body>
            <Toolbar
                title="Total"
                value={currencyFormatter(withdrawalData?.withdrawal_amount)}
                action={
                    <Button
                        id="btn-withdraw"
                        variant="contained"
                        color="secondary"
                        className={classes.withdrawalButton}
                        onClick={() => {
                            navigate('/3/withdrawal/review');
                        }}
                        disabled={!amount || amount > available || amount < 1000}
                    >
                        Withdraw
                    </Button>
                }
            />
        </>
    );
};
