import { useNavigate } from 'react-router-dom';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Header, Body } from '../../../../components';
import { Toolbar } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';
import { WithdrawalBody } from './WithdrawalBody';
import { PriceFormat } from '../../../../components/PriceFormat';
import { useWithdraw } from './useWithdraw';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    toolbar: {
        backgroundColor: theme.palette.secondary.light,
    },
    confirmButton: {
        width: '70%',
        height: 42,
        borderRadius: 30,
        boxShadow: 'none',
    },
    bold: {
        fontWeight: 800,
        display: 'flex',
    },
}));

export const WithdrawalConfirmation = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { data, withdrawalData, withdraw } = DrawDownContainer.useContainer();
    const { handleConfirm } = useWithdraw({ withdraw });

    return (
        <>
            <Header
                title="Withdrawal"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('/3/withdrawal')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
                rightActions={
                    <Button onClick={() => navigate('/3')} className={classes.button}>
                        Cancel
                    </Button>
                }
            />
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            maxWidth={235}
                            style={{ marginBottom: 27 }}
                        >
                            Review
                        </Typography>
                        <Typography variant="caption">
                            {currencyFormatter(
                                data.approval.remaining_balance - withdrawalData.withdrawal_amount
                            )}{' '}
                            credit left after withdrawal
                        </Typography>
                        <Box textAlign="center" mt={2}>
                            <Typography variant="caption" color="textSecondary">
                                Credit Withdrawn
                            </Typography>
                            <Typography variant="h4" component={Box} className={classes.bold}>
                                <Typography variant="h5" component="span" className={classes.bold}>
                                    $
                                </Typography>
                                <PriceFormat
                                    value={withdrawalData?.withdrawal_amount}
                                    noDollar
                                    decimalScale={2}
                                />
                            </Typography>
                        </Box>
                    </Box>
                    <WithdrawalBody />
                </Box>
            </Body>
            {/* <Toolbar
                title="Bank Account"
                value="Capital One ****1234"
                action={
                    <IconButton size="small" onClick={() => navigate('/3')}>
                        <ChevronRightIcon />
                    </IconButton>
                }
                className={classes.toolbar}
            /> */}
            <Toolbar
                title="Total"
                value={<PriceFormat value={withdrawalData?.withdrawal_amount} />}
                action={
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.confirmButton}
                        onClick={() => handleConfirm(withdrawalData)}
                    >
                        Confirm
                    </Button>
                }
            />
        </>
    );
};
