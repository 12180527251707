import { Route, Routes, useNavigate } from 'react-router-dom';
import { StatusPage } from '../../components';
import { IPayLaterOnboarding } from './IPayLaterOnboarding';
import { IPayLaterApplication } from './IPayLaterApplication';
import { useAppContext } from '../../../AppContext';
import { IPayLaterApplyLoader } from './IPayLaterApplyLoader';

const IPayLaterRouter = () => {
    const navigate = useNavigate();
    const {
        appState: { appIsLoading },
    } = useAppContext();

    return (
        !appIsLoading && (
            <Routes>
                <Route path="/*" element={<IPayLaterOnboarding />} />
                <Route path="/apply" element={<IPayLaterApplyLoader />} />
                <Route path="/application/:applicationId/*" element={<IPayLaterApplication />} />
                <Route
                    path="/submitted"
                    element={
                        <StatusPage
                            title="Your application is being processed"
                            subtitle="We will get back to you soon"
                            duration={7}
                            actionLabel="Go Back"
                            action={() => {
                                navigate('/paylater');
                            }}
                        />
                    }
                />
                <Route
                    path="/success"
                    element={
                        <StatusPage
                            title="Congratulations, your application is approved!"
                            subtitle="You can see your deals and credit status in your portal"
                            duration={7}
                            actionLabel="Launch portal in a new tab"
                            action={() => {
                                window.open('https://portal.app.golendica.com', '_blank');
                            }}
                        />
                    }
                />
            </Routes>
        )
    );
};

export const IPayLater = () => <IPayLaterRouter />;
