import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useEffect, useState } from 'react';

const CircularProgressTimer = ({ total, onComplete }) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer(currentValue => {
                if (currentValue + 1 > total) {
                    clearInterval(intervalId);
                    onComplete();
                    return currentValue;
                }
                return currentValue + 1;
            });
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                color="secondary"
                variant="determinate"
                value={(timer / total) * 100}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="secondary"
                >{`${timer}s`}</Typography>
            </Box>
        </Box>
    );
};

export const StatusPage = ({ title, subtitle, duration, action, actionLabel }) => {
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Box>
                <Typography variant="h5" color="textSecondary" align="center" paragraph>
                    {title}
                </Typography>
            </Box>
            <Box mt={2} sx={{ width: '70%' }}>
                <Typography variant="subtitle1" color="primary" align="center" paragraph>
                    {subtitle}
                </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <CircularProgressTimer total={duration} onComplete={action} />
            </Box>
            <Box mt={4} display="flex" justifyContent="center">
                <Button variant="contained" color="secondary" onClick={action}>
                    {actionLabel}
                </Button>
            </Box>
        </Box>
    );
};
