import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.light,
    },
    subtitleAmount: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export const SidebarPanelAlt = ({ infoList }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.root}
            component={Box}
            p={2}
            // flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
        >
            {infoList &&
                infoList.map((info, index) => (
                    <Grid
                        item
                        align={index === infoList.length - 1 ? 'right' : 'left'}
                        key={info.title}
                    >
                        <Typography variant="subtitle1" color="textSecondary">
                            {info.title}
                        </Typography>
                        <Typography
                            variant="h6"
                            component="span"
                            color="primary"
                            className={classes.subtitleAmount}
                        >
                            {info.subtitleAmount}
                        </Typography>
                    </Grid>
                ))}
        </Grid>
    );
};
