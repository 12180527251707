import { Box, Grid, Typography, Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.light,
    },
    subtitleAmount: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export const SidebarPanel = ({
    title,
    subtitle,
    subtitleAmount,
    buttonLabel,
    buttonId = '',
    onClick,
    disabled,
    underline = null,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.root}
            component={Box}
            p={2}
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <Typography variant="subtitle1" color="textSecondary">
                    {title}
                </Typography>
                <Typography variant="subtitle2" component="span" color="textSecondary">
                    {subtitle}
                </Typography>{' '}
                <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={classes.subtitleAmount}
                >
                    {subtitleAmount}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    color="primary"
                    id={buttonId}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {buttonLabel}
                </Button>
                {underline && (
                    <Typography variant="caption" align="center" display="block">
                        {underline}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
