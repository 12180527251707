import { Grid, Box, Typography } from '@mui/material';

export const FootNote = ({ lines }) => {
    return (
        lines &&
        lines.length && (
            <Grid
                container
                justifyContent="flex-end"
                component={Box}
                flex={1}
                alignItems="center"
                flexDirection="column"
            >
                <Grid
                    item
                    component={Box}
                    pt={2}
                    pb={2}
                    sx={{ width: '70%', whiteSpace: 'pre-line' }}
                >
                    {lines.map((line, index) => (
                        <Typography key={`${index}-${line.slice(5)}`} variant="caption" paragraph>
                            {line}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        )
    );
};
