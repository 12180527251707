import { useState } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Typography,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';

import { LinkButton } from '@lendica/components';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.light,
        fontWeight: theme.typography.fontWeightSemibold,
    },
    body: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'auto',
    },
}))(TableCell);

const useStyles = makeStyles(theme => ({
    root: {
        'position': 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            height: gradient => (gradient ? '60%' : 0),
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            background: `linear-gradient(rgba(252, 252, 253, 0), ${theme.palette.background.paper})`,
        },
        'tableLayout': 'fixed',
    },
    container: {
        maxHeight: 440,
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export const SidebarTable = ({ data, limit, loading = false, gradient = false }) => {
    const rows = limit ? data.rows.slice(0, limit) : data.rows;

    const classes = useStyles(gradient);

    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('status');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const skeletonArray = Array(10).fill('');

    return (
        <>
            <TableContainer className={classes.container}>
                <Table stickyHeader dense="true" size="small" className={classes.root}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {data.columns.map(column => (
                                <StyledTableCell
                                    align={column.align}
                                    key={column.field}
                                    width={column.width}
                                    sortDirection={orderBy === column.field ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.field}
                                        direction={orderBy === column.field ? order : 'asc'}
                                        hideSortIcon={gradient || column.field === 'action'}
                                        onClick={
                                            !gradient && column.field !== 'action'
                                                ? createSortHandler(column.field)
                                                : null
                                        }
                                    >
                                        {column.headerName}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading &&
                            skeletonArray.map((row, index) => (
                                <TableRow key={`skrow-${index}`}>
                                    {data.columns.map(column => (
                                        <StyledTableCell
                                            align={column.align}
                                            key={`${row.id}-${column.field}`}
                                            width={column.width}
                                        >
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {!loading &&
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, i) => (
                                    <TableRow hover key={`tb-${row.id}-${i}`}>
                                        {data.columns.map(column => (
                                            <StyledTableCell
                                                align={column.align}
                                                key={`${row.id}-${column.field}`}
                                                width={column.width}
                                            >
                                                {column.type !== 'action' && (
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {column.formatter
                                                            ? column.formatter(row[column.field])
                                                            : row[column.field]}
                                                    </Typography>
                                                )}
                                                {column.type === 'action' && (
                                                    <LinkButton
                                                        id={`btn-invoice-action-${row.id}`}
                                                        aria-label="Invoice Action"
                                                        variant="body2"
                                                        color="secondary"
                                                        onClick={row[column.field].handler}
                                                    >
                                                        {row[column.field].text}
                                                    </LinkButton>
                                                )}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!gradient && (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </>
    );
};
