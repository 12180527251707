import { useState, useRef } from 'react';
import { Box, IconButton, Typography, Grid, useMediaQuery } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import {
    RoundedButton,
    Toolbar,
    FeatureHeader,
    LinkButton,
    PayLaterLogo,
} from '@lendica/components';
import { Header, Loader, Body, Footer } from '../../components';
import { useApi } from './plOnboardingAPI';
import { useAppContext, useDataContext } from '../../../AppContext';
import { settings } from '../../../settings';
import { Guide, GuideStep, ScrollArea } from '../../components';

const FakeDropzone = () => {
    return (
        <Box
            sx={{
                'mb': 4,
                'position': 'relative',
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    height: '80%',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    background: `linear-gradient(rgba(252, 252, 253, 0), #FCFCFD)`,
                },
            }}
        >
            <Box
                sx={{
                    mt: 2,
                    p: 1,
                    borderRadius: 2,
                    border: theme => `0.2px solid ${theme.palette.divider}`,
                }}
            >
                <Box
                    sx={{
                        height: 200,
                        backgroundColor: '#F2F2F8',
                        border: '1px dashed #D7D7E0',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography color="secondary" variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
                        + NEW
                    </Typography>
                    <Typography color="text.disabled" variant="subtitle1">
                        Drag and drop or click to upload invoices.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export const IPayLaterOnboarding = () => {
    const navigate = useNavigate();
    const {
        appState: { environment, userAccessType },
    } = useAppContext();
    const [
        {
            company: { lendica_token },
        },
    ] = useDataContext();
    const plOnboardingAPI = useApi(environment);

    const [isLoading, setIsLoading] = useState(false);

    const headerRef = useRef();
    const containerRef = useRef();
    const scrollToRef = useRef();
    const match = useMediaQuery(settings.stickyHeaderMediaQuery);

    const handleActivate = async () => {
        try {
            setIsLoading(true);
            navigate(
                `application/${
                    (await plOnboardingAPI.createApplication(lendica_token)).data.application_id
                }`
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <>
                    <Header />
                    <Body>
                        <Loader />
                    </Body>
                </>
            ) : (
                <>
                    <Header
                        rightActions={
                            <IconButton size="small" onClick={() => navigate('/settings')}>
                                <MoreVertIcon />
                            </IconButton>
                        }
                        ref={headerRef}
                    />
                    <Body
                        sx={{ height: '100%' }}
                        display="flex"
                        flexDirection="column"
                        ref={containerRef}
                    >
                        <Box sx={{ flex: '1 0 auto' }} px={2}>
                            <Box sx={{ mt: 2 }}>
                                <FeatureHeader
                                    name={<PayLaterLogo />}
                                    total={80000}
                                    available={66400}
                                    unpaid={16456}
                                    entity="invoices"
                                    entityNumber={2}
                                />
                            </Box>
                            <FakeDropzone />
                            <Box mt={-4} sx={{ width: '70%', zIndex: 200 }}>
                                <Typography variant="h5" color="textPrimary" component="h5">
                                    Activate now to instantly delay payments to your vendors.
                                </Typography>
                            </Box>
                            <Grid justifyContent="space-between" container component={Box} mt={4}>
                                <Grid item>
                                    <RoundedButton
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleActivate}
                                        disabled={userAccessType === 'VIEW_ONLY'}
                                    >
                                        Activate Now
                                    </RoundedButton>
                                </Grid>
                                <Grid item>
                                    <ScrollArea
                                        title="See How it Works"
                                        to={scrollToRef}
                                        offset={match ? headerRef : undefined}
                                        container={containerRef}
                                    />
                                </Grid>
                            </Grid>
                            <Box mt={8}>
                                <Grid
                                    justifyContent="flex-end"
                                    container
                                    component={Box}
                                    ref={scrollToRef}
                                >
                                    <Box sx={{ width: '70%', textAlign: 'right' }}>
                                        <Typography variant="h5" color="textPrimary" component="h5">
                                            Just submit an invoice and select your payment terms.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid justifyContent="center" container component={Box} mt={2}>
                                    <img
                                        src="https://static.golendica.com/ipaylater_mkt.png"
                                        width="85%"
                                        alt="paylater example screens"
                                    />
                                </Grid>
                            </Box>
                            <Box mt={8}>
                                <Guide title="How it works">
                                    <GuideStep
                                        stepNumber="1"
                                        title="Activate PayLater"
                                        body={
                                            <>
                                                Provide basic information to unlock up to $2,500,000
                                                in payables finance.{' '}
                                                {userAccessType === 'ADMIN' && (
                                                    <LinkButton
                                                        variant="body2"
                                                        color="secondary"
                                                        onClick={handleActivate}
                                                    >
                                                        Activate Now
                                                    </LinkButton>
                                                )}
                                            </>
                                        }
                                    />
                                    <GuideStep
                                        stepNumber="2"
                                        title="Submit Invoice"
                                        body="Upload an invoice and select how long you’d like to delay payment."
                                    />
                                    <GuideStep
                                        stepNumber="3"
                                        title="Simple Payback"
                                        body="Vendors are paid directly, Lendica collects a deposit, and schedules the remainder of 2 to 10 weekly payments."
                                    />
                                </Guide>
                            </Box>
                            <Box mt={8}>
                                <Typography variant="h5" color="textPrimary" component="h5">
                                    Questions?
                                </Typography>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    component={Box}
                                    mt={2}
                                >
                                    <Grid item>
                                        <Typography variant="h6" color="textPrimary">
                                            Schedule a meeting with us
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <RoundedButton
                                            variant="contained"
                                            color="secondary"
                                            href="https://calendly.com/lendica/intro"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Schedule
                                        </RoundedButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box sx={{ flexShrink: 0 }} mt={8}>
                            <Footer />
                        </Box>
                    </Body>
                    <Toolbar
                        action={
                            <RoundedButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disabled={userAccessType === 'VIEW_ONLY'}
                                onClick={handleActivate}
                            >
                                Activate Now
                            </RoundedButton>
                        }
                    />
                </>
            )}
        </>
    );
};
