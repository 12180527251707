import { ArrowDropDown, Info } from '@mui/icons-material';
import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Link,
} from '@mui/material';

import { makeStyles, withStyles } from '@mui/styles';

import { currencyFormatter, percentageFormatter } from '@lendica/utils';
import { DrawDownContainer } from '../../DrawDownContainer';

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700,
    },
    modal: {
        width: 230,
    },
    item: {
        'display': 'flex',
        'alignItems': 'center',
        'padding': '6px 16px',
        'borderBottom': '1px solid #ccc',

        '& > div:last-child': {
            width: 80,
        },
        '& > div:first-child': {
            flex: 1,
        },
        '&:hover': {
            backgroundColor: '#8C8CA11F',
        },
    },
}));

const CustomAccordion = withStyles({
    root: {
        'borderTop': '1px solid #D9D9E5',
        'borderBottom': '1px solid #D9D9E5',
        'boxShadow': 'none',
        'marginBottom': 24,
        '& > div:first-child': {
            paddingLeft: 0,
            paddingRight: 8,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

export const WithdrawalBody = ({ showMore }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const { data, withdrawalData } = DrawDownContainer.useContainer();

    const open = Boolean(anchorEl);
    const id = open ? 'withdrawal-popover' : undefined;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Box mt={6} display="flex" flex="0 0 auto">
                <Box flex="1">
                    <Typography variant="caption">Rate</Typography>
                    <Box display="flex">
                        <Typography variant="h6" component={Box} className={classes.bold}>
                            {withdrawalData?.factor_rate ?? 1.21}
                        </Typography>
                        {showMore && (
                            <Box ml={1}>
                                <Button
                                    variant="outlined"
                                    endIcon={<Info />}
                                    size="small"
                                    onClick={handleClick}
                                >
                                    more
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box className={classes.modal}>
                                        <Box p={2} pb={1}>
                                            <Typography variant="caption">
                                                Faster paybacks mean lower factor rates. Pay off
                                                early and save.
                                            </Typography>
                                        </Box>
                                        <Box className={classes.item}>
                                            <Typography
                                                variant="body2"
                                                className={classes.bold}
                                                component="div"
                                            >
                                                No. of Days
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className={classes.bold}
                                                component="div"
                                            >
                                                Rate
                                            </Typography>
                                        </Box>
                                        {Object.entries(data?.approval?.factor_rates).map(
                                            ([key, value], index) => (
                                                <Box
                                                    className={classes.item}
                                                    key={`rate-table-${index}`}
                                                >
                                                    <Typography variant="body2" component="div">
                                                        {key}
                                                    </Typography>
                                                    <Typography variant="body2" component="div">
                                                        {JSON.stringify(value)}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                </Popover>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Withdrawal Fee</Typography>
                    <Typography variant="h6" component={Box} className={classes.bold}>
                        {percentageFormatter(withdrawalData?.origination_fee_percentage ?? 0.05)}
                    </Typography>
                </Box>
                <Box flex="1.4" textAlign="right">
                    <Typography variant="caption">Amount Funded</Typography>
                    <Typography variant="h6" component={Box} className={classes.bold}>
                        {currencyFormatter(withdrawalData?.net_funded_amount ?? 0)}
                    </Typography>
                </Box>
            </Box>
            <Box mt={3} mb={6} display="flex" flex="0 0 auto">
                <Box flex="1">
                    <Typography variant="caption">Term Length</Typography>
                    <Typography variant="h6" component={Box} className={classes.bold}>
                        6 months
                    </Typography>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Weekly Payment</Typography>
                    <Typography variant="h6" component={Box} className={classes.bold}>
                        {currencyFormatter(withdrawalData?.total_pmt / 26.0 ?? 0)}
                    </Typography>
                </Box>
                <Box flex="1.4" textAlign="right">
                    <Typography variant="caption">Total Payment</Typography>
                    <Typography variant="h6" component={Box} className={classes.bold}>
                        {currencyFormatter(withdrawalData?.total_pmt ?? 0)}
                    </Typography>
                </Box>
            </Box>
            <CustomAccordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="body2">Terms and conditions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        The terms and conditions related to your Future Receivable Sales Agreement
                        can be found{' '}
                        <Link
                            variant="inherit"
                            color="textSecondary"
                            href="https://home.golendica.com/agreement-drawdown"
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            here
                        </Link>
                        . Please review before signing.
                    </Typography>
                </AccordionDetails>
            </CustomAccordion>
            {showMore && (
                <Typography variant="caption">
                    *By clicking 'WITHDRAW' you agree to all{' '}
                    <Link
                        variant="inherit"
                        color="textSecondary"
                        href="https://home.golendica.com/agreement-drawdown"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="always"
                    >
                        terms and conditions
                    </Link>{' '}
                    of DrawDown product.
                </Typography>
            )}
        </>
    );
};
