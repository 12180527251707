import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

import { useApi } from './api';
import { defaultDDInit } from './data';
import { useAppContext } from '../../../AppContext';

// For simplicity this container has state & methods for sub-pages within DrawDown feature like Withdraw or Payment
// When it grows bigger split out sub-page state to its own container
const useDrawDownContainer = () => {
    const [state, setState] = useState({});
    const {
        appState: {
            credentials: {
                lendica_token,
                partner_name,
                company_name,
                company_id,
                company_access_token,
            },
            environment,
        },
        setError,
    } = useAppContext();
    const api = useApi(environment);

    const updateData = data => {
        setState(currentState => ({
            ...currentState,
            data: {
                ...currentState.data,
                ...data,
            },
        }));
    };

    const updateDrawDown = async () => {
        try {
            setState(currentState => ({ ...currentState, isLoading: true }));
            const response = await api.init({
                lendica_token,
                partner_name,
                company_name,
                company_id,
                company_access_token,
            });
            if (response.successful) {
                if (
                    !response.data?.company?.activated_drawdown &&
                    Object.keys(response.data?.approval).length === 0
                ) {
                    setState(currentState => ({
                        ...currentState,
                        data: {
                            ...response.data,
                            ...defaultDDInit,
                        },
                    }));
                    return;
                }
                updateData(response.data);
            } else {
                setState(currentState => ({
                    ...currentState,
                    data: {
                        ...defaultDDInit,
                    },
                }));
                setError('Failed');
            }
        } catch {
            setError('We have trouble connecting to the server. Please try again later.');
        } finally {
            setState(currentState => ({ ...currentState, isLoading: false }));
        }
    };

    useEffect(() => {
        updateDrawDown();
    }, [lendica_token]);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            localStorage.setItem('DrawDown', JSON.stringify(state));
        }, [state]);
    }

    return {
        ...state,
        async activateApply() {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                if (!lendica_token && !state?.data?.company?.lendica_token) {
                    throw new Error("Sorry, you don't have access to the application.");
                }
                const response = await api.activateApply({
                    lendica_token: lendica_token ?? state?.data?.company?.lendica_token,
                });
                if (response.successful) return response.data;
                else
                    throw new Error(
                        'We have trouble connecting to the server. Please try again later.'
                    );
            } catch (e) {
                setError(e.message);
                throw new Error(e.message);
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        },
        async activateOffer() {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const response = await api.activateOffer({
                    lendica_token: lendica_token ?? state?.data?.company?.lendica_token,
                });
                if (!response.successful)
                    throw new Error(
                        'We have trouble connecting to the server. Please try again later.'
                    );
            } catch (e) {
                setError(e.message);
                throw new Error(e.message);
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        },
        async withdraw(withdrawalData) {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const res = await api.withdraw(
                    { lendica_token: lendica_token ?? state?.data?.company?.lendica_token },
                    withdrawalData
                );
                if (res.successful) {
                    setState(currentState => ({
                        ...currentState,
                        data: {
                            ...currentState.data,
                            approval: {
                                ...currentState.data.approval,
                                ...res.data.approval,
                            },
                        },
                        withdrawalData: {
                            ...currentState.withdrawalData,
                            ...res.data.withdrawal,
                        },
                    }));
                } else {
                    throw new Error(
                        'We have trouble connecting to the server. Please try again later.'
                    );
                }
            } catch (e) {
                setError(e.message);
                throw new Error(e.message);
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        },
        async pay(withdrawals) {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const res = await api.pay(
                    { lendica_token: lendica_token ?? state?.data?.company?.lendica_token },
                    withdrawals
                );
                if (res.successful) {
                    setState(currentState => ({
                        ...currentState,
                        data: {
                            ...currentState.data,
                            approval: {
                                ...currentState.data.approval,
                                ...res.data.approval,
                            },
                            early_payment: res.data.early_payment,
                        },
                    }));
                } else {
                    throw new Error(
                        'We have trouble connecting to the server. Please try again later.'
                    );
                }
            } catch (e) {
                setError(e.message);
                throw new Error(e.message);
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        },
        updateData,
        updateDrawDown,
        selectActivity(activity) {
            setState(currentState => ({ ...currentState, selectedActivity: activity }));
        },
        setWithdrawal(amount, fee, rate, term) {
            setState(currentState => ({
                ...currentState,
                withdrawalData: {
                    ...currentState.withdrawalData,
                    withdrawal_amount: amount,
                    net_funded_amount: amount * (1 - fee),
                    total_pmt: amount * rate,
                    factor_rate: rate,
                    origination_fee_percentage: fee,
                    term_length: term,
                },
            }));
        },
        setSelectedWithdrawals(items) {
            setState(currentState => ({ ...currentState, selectedWithdrawals: items }));
        },
    };
};

export const DrawDownContainer = createContainer(useDrawDownContainer);
