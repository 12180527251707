export const defaultInit = {
    company: {
        lendica_token: null,
        name: 'Company',
    },
    fundnow: {
        activated: false,
        invoices: [
            {
                action: 1,
                invoice_id: '6facdda4-f178-11eb-9642-fe4396e65c0f',
                order_id: '00001932',
                order_timestamp: '2021-05-10T21:50:38Z',
                status: 1,
                total_price: 9200,
            },
            {
                action: 2,
                invoice_id: '66078588-f178-11eb-9642-fe4396e65c0f',
                order_id: '00002058',
                order_timestamp: '2021-06-10T23:19:27Z',
                status: 2,
                total_price: 159435,
            },
            {
                action: 0,
                invoice_id: '65edf4ec-f178-11eb-9642-fe4396e65c0f',
                order_id: '00002056',
                order_timestamp: '2021-06-10T17:16:45Z',
                status: 0,
                total_price: 10250,
            },
        ],
        product: { name: 'fundnow', marketing: null },
        summary: {
            outstanding_invoices: 3,
            invoice_total: 2500000,
            number_of_live_deals: 0,
            funding_credit_left: 2500000,
            funding_credit_used: 0,
        },
    },
    paylater: {
        activated: false,
        invoices: [
            {
                action: 1,
                invoice_id: '6facdda4-f178-11eb-9642-fe4396e65c0f',
                order_id: '00001932',
                order_timestamp: '2021-05-10T21:50:38Z',
                status: 1,
                total_price: 9200,
            },
            {
                action: 2,
                invoice_id: '66078588-f178-11eb-9642-fe4396e65c0f',
                order_id: '00002058',
                order_timestamp: '2021-06-10T23:19:27Z',
                status: 2,
                total_price: 159435,
            },
            {
                action: 0,
                invoice_id: '65edf4ec-f178-11eb-9642-fe4396e65c0f',
                order_id: '00002056',
                order_timestamp: '2021-06-10T17:16:45Z',
                status: 0,
                total_price: 10250,
            },
        ],
        product: { name: 'fundnow', marketing: null },
        summary: {
            num_invoices: 9,
            invoice_total: 40000,
            number_of_live_deals: 0,
            funds_paid: 2500000,
            funds_will_paid: 0,
        },
    },
    products: {
        fundnow: true,
        paylater: true,
        drawdown: true,
    },
    settings: {
        current_page: 'fundnow',
        last_login: '',
    },
};
