import React, { FC, useState } from 'react';
import {
    FormControl,
    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    OutlinedInputProps
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Props extends OutlinedInputProps {
    helperText?: string
}
export const PasswordTextField: FC<Props> = ({ className,helperText, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel
                htmlFor={props.id}
                error={props.error}
            >
                {props.label}
            </InputLabel>
            <OutlinedInput
                {...props}
                autoComplete="on"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText error={props.error}>{helperText}</FormHelperText>
        </FormControl>
    )
}
