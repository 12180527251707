export const getBackendURL = (env = 'SANDBOX') => {
    if (typeof env === 'string') {
        env = env.toUpperCase();
    }
    if (/PRODUCTION/gi.test(env)) {
        return 'https://prod.golendica.com/api/v1';
    }
    if (/DEV/gi.test(env)) {
        return 'https://idev.golendica.com/api/v1';
    }
    if (/QA/gi.test(env)) {
        return 'https://iqa.golendica.com/api/v1';
    }
    if (/LOCAL/gi.test(env)) {
        if (process.env.NODE_ENV === 'development') {
            return 'https://idev.golendica.com/api/v1';
        } else {
            // return 'http://127.0.0.1:8000/api/v1';
            return 'https://sandbox.golendica.com/api/v1'
        }
    }
    return 'https://sandbox.golendica.com/api/v1';
};

export const getBackendURLFromHost = (hostname: string) => {
    hostname = hostname.toUpperCase();
    if (/SANDBOX/gi.test(hostname)) {
        return 'https://sandbox.golendica.com/api/v1';
    }
    if (/DEV/gi.test(hostname)) {
        return 'https://idev.golendica.com/api/v1';
    }
    if (/QA/gi.test(hostname)) {
        return 'https://iqa.golendica.com/api/v1';
    }
    if (/LOCAL/gi.test(hostname) || /127\.0\.0\.1/gi.test(hostname)) {
        if (process.env.NODE_ENV === 'development') {
            return 'https://idev.golendica.com/api/v1';
        } else {
            // return 'http://127.0.0.1:8000/api/v1';
            return 'https://sandbox.golendica.com/api/v1'
        }
    }
    return 'https://prod.golendica.com/api/v1';
};

export const getEnvFromHost = (hostname: string) => {
    hostname = hostname.toUpperCase();
    if (/SANDBOX/gi.test(hostname)) {
        return 'sandbox';
    }
    if (/DEV/gi.test(hostname)) {
        return 'idev';
    }
    if (/QA/gi.test(hostname)) {
        return 'iqa';
    }
    if (/LOCAL/gi.test(hostname) || /127\.0\.0\.1/gi.test(hostname)) {
        return 'test';
    }
    return 'prod';
};
