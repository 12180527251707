import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { currencyFormatter } from '@lendica/utils';
import { useAppContext } from '../../../../../AppContext';
import { DrawDownContainer } from '../../DrawDownContainer';

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 800,
    },
    card: {
        borderRadius: 12,
        borderColor: theme.palette.background.cardBorder,
        borderWidth: 1,
        backgroundColor: theme.palette.background.cardLight,
        borderStyle: 'solid',
    },
    blue: {
        color: theme.palette.secondary.main,
    },
    renew: {
        width: '100%',
        borderRadius: 20,
        height: 30,
        marginTop: theme.spacing(2),
    },
    error: {
        color: theme.palette.error.main,
    },
}));

export const PaymentWidget = ({ payment, due, expire }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const { setAppState } = useAppContext();
    const { activateApply } = DrawDownContainer.useContainer();
    const classes = useStyles();

    const handlePay = () => {
        navigate('/3/payment/pay-early');
        setError(!error);
    };

    const openForm = async () => {
        try {
            const responseData = await activateApply();
            navigate(`/3/application/${responseData?.application_id}`);
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't load the renew credit form, please try again later. ${error}`,
                },
            }));
        }
    };

    return (
        <Box display="flex" mb={3}>
            <Box className={classes.card} mr={3} flex="1">
                <Box p={2}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={-1}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Payment Center
                        </Typography>
                        <IconButton
                            id="btn-payment-center-arrow"
                            size="small"
                            onClick={() => navigate('/3/payment')}
                        >
                            <ArrowForwardIosIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box pt={1} textAlign="center">
                        {error ? (
                            <Typography variant="overline" color="error">
                                Overdue for {due}
                            </Typography>
                        ) : (
                            <Typography variant="overline">Next Due {due}</Typography>
                        )}
                        <Typography
                            variant="h5"
                            className={clsx(classes.bold, classes.blue, error && classes.error)}
                        >
                            {currencyFormatter(payment ?? 0)}
                        </Typography>
                        <Button id="btn-pay-early" variant="outlined" className={classes.renew} onClick={handlePay}>
                            Pay Early
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.card} flex="1">
                <Box p={2}>
                    <Box display="flex" height="30" mt={-1} alignItems="center">
                        <Typography variant="subtitle1" color="textSecondary">
                            Credit Status
                        </Typography>
                    </Box>
                    <Box pt={1} textAlign="center">
                        <Typography variant="overline">Credit Expires in</Typography>
                        <Typography variant="h5" className={clsx(classes.bold, classes.blue)}>
                            {expire} Days
                        </Typography>
                        <Button
                            variant="outlined"
                            className={classes.renew}
                            disabled={expire > 30}
                            onClick={openForm}
                        >
                            Renew
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
