import { useNavigate } from 'react-router-dom';
import { DrawDownContainer } from '../../DrawDownContainer';
import { ActivityListItem } from '../../../../components/ActivityListItem';
import { useActivity } from './useActivity';

export const ActivityItem = ({ activity }) => {
    const navigate = useNavigate();
    const { selectActivity } = DrawDownContainer.useContainer();

    const { getActivityType, getActivityStatus, getDate } = useActivity(
        activity.activity_type,
        activity.status
    );

    const handleClick = () => {
        navigate('/3/activities/detail');
        selectActivity(activity);
    };

    return (
        <ActivityListItem
            activity_type={activity.activity_type}
            id={activity.id}
            title={getActivityType()}
            caption={`${getDate(activity.activity_date)}, w${
                activity?.withdrawal_id?.slice(0, 8) ?? activity?.activity_id?.slice(0, 8) ?? ''
            }`}
            amount={activity.activity_amount}
            status={getActivityStatus()}
            handleClick={handleClick}
        />
    );
};
