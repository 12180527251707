import { useParams } from 'react-router-dom';

import { useDataContext } from '../../../AppContext';
import { Loader } from '../../components';

import { FNInvoiceDetails } from './FNInvoiceDetails';
import { FNConfirmEmail } from './FNConfirmEmail';
import { FNSeeProgress } from './FNSeeProgress';

export const FundNowOverlay = () => {
    const [data] = useDataContext();
    const { orderId } = useParams();

    const findInvoice = () => {
        return data?.fundnow?.invoices?.find(invoice => invoice.order_id === orderId);
    };

    const getOverlay = () => {
        const invoiceData = findInvoice();
        switch (invoiceData?.action ?? 9) {
            case 0:
                return <FNInvoiceDetails invoiceId={invoiceData.invoice_id} />;
            case 1:
            case 2:
                return <FNConfirmEmail invoiceId={invoiceData.invoice_id} />;
            case 3:
            case 4:
                return <FNSeeProgress invoiceId={invoiceData.invoice_id} />;
            case 9:
                return <Loader />;
            default:
                break;
        }
    };

    return getOverlay();
};
