import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, TextField, Typography, Button } from '@mui/material';
import { Overlay, OverlayFieldset, OverlayOption } from '@lendica/components';
import { Loader } from '../../components';

import { confirmEmail, confirmDelivery } from '../../../api';

import { useAppContext, useDataContext } from '../../../AppContext';

export const FNConfirmEmail = ({ invoiceId }) => {
    const {
        appState: { fnIsLoading },
        setAppState,
    } = useAppContext();
    const [data, setData] = useDataContext();
    const [termLength, setTermLength] = useState(30);
    // const [error, setError] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
                const contactInfo = await confirmEmail({
                    lendica_token: data?.company?.lendica_token,
                    invoice_id: invoiceId,
                });
                setData(currentState => ({
                    ...currentState,
                    details: { ...currentState.details, ...contactInfo },
                }));
            } catch (error) {
                setAppState(currentState => ({
                    ...currentState,
                    snackbar: {
                        open: true,
                        message: `We couldn't load the invoice contacts, please try again later. ${error}`,
                    },
                }));
            } finally {
                setAppState(currentState => ({ ...currentState, fnIsLoading: false }));
            }
        })();
    }, [invoiceId]);

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
            await confirmDelivery({
                lendica_token: data?.company?.lendica_token ?? null,
                invoice_id: invoiceId ?? null,
                seller_email: data?.details?.seller_email ?? null,
                buyer_email: data?.details?.buyer_email ?? null,
                term_length: termLength,
            });
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message:
                        'Confirmation emails are sent to you and your customer. Please follow the instructions in your email.',
                },
            }));
            navigate('/1');
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't send the confirmation email, please try again later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({ ...currentState, fnIsLoading: false }));
        }
    };

    const handleChange = e => {
        setData(currentState => ({
            ...currentState,
            details: {
                ...currentState.details,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const handleOverlayClose = () => {
        navigate('/1');
    };

    const mapValuesToLabels = values => {
        return values.map(v => ({
            value: v,
            label: `${v} days`,
        }));
    };

    return fnIsLoading ? (
        <Loader />
    ) : (
        <Overlay title="Confirm Delivery" handleClose={handleOverlayClose}>
            <form onSubmit={handleSubmit}>
                <OverlayFieldset subtitle={`Invoice Contact Details`}>
                    <Grid item component={Box} py={4} width="70%">
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            align="center"
                            gutterBottom
                        >
                            Confirm your email
                        </Typography>
                        <Typography variant="subtitle1" color="secondary" align="center" paragraph>
                            From: {data?.details?.seller_name ?? ''}
                        </Typography>
                        <TextField
                            type="email"
                            name="seller_email"
                            label="Your Email"
                            value={data?.details?.seller_email ?? ''}
                            onChange={handleChange}
                            // error={error['seller_email']}
                            // helperText={
                            //     error['seller_email'] ? 'Please input your email address' : ''
                            // }
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item component={Box} py={4} width="70%">
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            align="center"
                            gutterBottom
                        >
                            Confirm your customer's email
                        </Typography>
                        <Typography variant="subtitle1" color="secondary" align="center" paragraph>
                            To: {data?.details?.buyer_name ?? ''}
                        </Typography>
                        <TextField
                            type="email"
                            name="buyer_email"
                            label="Your Customer's Email"
                            value={data?.details?.buyer_email ?? ''}
                            onChange={handleChange}
                            // error={error['buyer_email']}
                            fullWidth
                            required
                        />
                    </Grid>
                </OverlayFieldset>
                <OverlayFieldset subtitle="Confirm the term of this invoice">
                    <OverlayOption
                        summary="Payment period you agreed with your customer"
                        min={28}
                        max={56}
                        marks={mapValuesToLabels([28, 42, 56])}
                        value={termLength}
                        handleChange={(e, newValue) => setTermLength(newValue)}
                    />
                </OverlayFieldset>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    component={Box}
                    my={6}
                >
                    <Grid item>
                        <Button id="btn-invoice-details-action" type="submit" variant="contained" color="secondary">
                            Send Confirmation
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Overlay>
    );
};
