import { Logo } from '@lendica/components';
import { currencyFormatter } from '@lendica/utils';
import { useState } from 'react';

const Card = ({ label, value }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'fit-content',
                width: 'fit-content',
                padding: '1rem 1rem',
                textAlign: 'center',
            }}
        >
            <h4
                style={{ margin: 0, color: '#8C8CA1', fontWeight: 350, marginBottom: '0.5rem' }}
            >{`${label}`}</h4>
            <h4 style={{ margin: 0 }}>{currencyFormatter(value)}</h4>
        </div>
    );
};

export default function PayLaterButton() {
    const [status, setStatus] = useState(null);
    const approvedTerms = {
        error: [],
        company: {
            id: 'ba27942e-e5af-11ec-bccb-0242ac130007',
            partner_company_uuid: '006c545e-a4f6-4e86-88ea-25319031d53d',
            company_name: 'Drift',
        },
        approval: {
            status: 'approved',
            details: {
                product_name: 'paylater',
                credit_limit: 500000,
                credit_balance: 1960,
                credit_available: 498040,
                number_of_active_deals: 1,
            },
        },
        deals: [
            {
                id: '2e6a81ed-72d9-468a-ae96-90cf919fadf5',
                partner_invoice_uuid: '5ec6c168-385f-4908-9571-2ba496ce3e75',
                status: 'pending',
                product_name: 'paylater',
            },
        ],
    };

    const onComplete = () => {
        setStatus('Approved');
    };

    const openHandler = () => {
        lendica.paylater.openInApp(
            'http://localhost:5001/5ba5f81a-aa6c-11ec-81d4-0242ac1d0008/select-terms/final/57a00886-abfd-11ec-a34a-0242ac1e0007',
            onComplete
        );
        // lendica.openPopup("http://localhost:5001/140de534-e1bd-11ec-a313-0242c0a8c007/select-terms/preview", onComplete)
    };

    return (
        <div
            className="container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'fit-content',
                width: 480,
                borderRadius: '6px',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
        >
            <div
                className="header"
                style={{
                    height: 'fit-content',
                    width: '480px',
                    padding: '0rem 1.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#2E3192',
                    background: '#F2F2F8',
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                    boxSizing: 'border-box',
                }}
            >
                <h3 style={{ fontWeight: 500, fontSize: 18 }}>Pay With Lendica</h3>
                <button className="open-popup" id="open-popup" onClick={openHandler}>
                    PayLater <span className="open-popup-span">with</span>{' '}
                    <Logo dark={false} height={14} />
                </button>
            </div>

            <div
                className="body"
                style={{
                    padding: '1.5rem',
                    border: '1px solid #EDEDF4',
                    width: 480,
                    boxSizing: 'border-box',
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                }}
            >
                {status == 'Approved' ? (
                    <h4 style={{ margin: '0.5rem 0' }}>Approved Terms</h4>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h5 style={{ fontWeight: 500 }}>Invoice No. 22626</h5>
                        <h5>{currencyFormatter(2800)}</h5>
                    </div>
                )}
                {status == 'Approved' && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <Card label={'Limit'} value={approvedTerms.approval.details.credit_limit} />
                        <Card
                            label={'Balance'}
                            value={approvedTerms.approval.details.credit_balance}
                        />
                        <Card
                            label={'Available'}
                            value={approvedTerms.approval.details.credit_available}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
