/** @license Lendica iTab v1.0.0
 * Copyright © 2022 Lendica Corp. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Lendica Corp. <info@golendica.com>, January 2022
 */

import { render, unmountComponentAtNode } from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { MemoryRouter } from 'react-router-dom';
// import { InvoiceButton } from '@lendica/invoice-button';

import { App } from './App';
import { invoiceCache } from './invoiceCache';
import { initAnalytics } from './analytics';
import PayLaterButton from './Sidebar/components/PayLaterButton';

let root = null;

function renderWidget(credentials, config, ...rest) {
    class WidgetComponent extends HTMLElement {
        connectedCallback() {
            const shadowRoot = this.attachShadow({ mode: 'open' });
            const mountPoint = document.createElement('div');
            this.mountPoint = mountPoint;
            const reactRoot = shadowRoot.appendChild(mountPoint);

            const jss = create({
                ...jssPreset(),
                insertionPoint: reactRoot,
            });

            const cache = createCache({
                key: 'my-prefix-key',
                prepend: true,
                container: shadowRoot,
            });

            render(
                <StyledEngineProvider injectFirst>
                    <CacheProvider value={cache}>
                        <StylesProvider jss={jss}>
                            <MemoryRouter>
                                <App
                                    root={root}
                                    credentials={credentials}
                                    config={config}
                                    {...rest}
                                />
                            </MemoryRouter>
                        </StylesProvider>
                    </CacheProvider>
                </StyledEngineProvider>,
                mountPoint
            );
        }

        disconnectedCallback() {
            unmountComponentAtNode(this.mountPoint);
        }
    }

    if (root) return;

    smoothscroll.polyfill();

    if (!customElements.get('widget-commponent')) {
        customElements.define('widget-commponent', WidgetComponent);
    }
    root = document.createElement('widget-commponent');
    window.document.body.appendChild(root);
}

export function init(credentials, config, ...rest) {
    if (document.readyState === 'complete') {
        renderWidget(credentials, config, ...rest);
        return;
    }
    window.addEventListener('load', () => {
        renderWidget(credentials, config, ...rest);

        initAnalytics(credentials);
    });
}

export function destroy() {
    invoiceCache.clear();
    if (!root) return;
    window.document.body.removeChild(root);
    root = null;
}

export function open(invoiceId) {
    if (document.readyState !== 'complete') {
        throw new Error('DOM not ready');
    }
    if (!root) {
        throw new Error('Lendica must be initialized to open');
    }
    root.dispatchEvent(new CustomEvent('open-widget', { detail: { invoiceId } }));
}

export function apply(onComplete) {
    if (document.readyState !== 'complete') {
        throw new Error('DOM not ready');
    }
    if (!root) {
        throw new Error('Lendica must be initialized to open');
    }
    root.dispatchEvent(new CustomEvent('apply', { detail: { onComplete } }));
}

export const paylater = {
    openInApp: openInApp,
};

function openInApp(url, onComplete) {
    if (document.readyState !== 'complete') {
        throw new Error('DOM not ready');
    }
    if (!root) {
        throw new Error('Lendica must be initialized to open');
    }
    root.dispatchEvent(
        new CustomEvent('open-popup', { detail: { url: url, onComplete: onComplete } })
    );
}

export const invoices = {
    getById(id) {
        if (id === undefined) {
            throw new Error('Invoice Id must be defined');
        }
        return invoiceCache.getById(id);
    },
    onLoad(subscriber) {
        return invoiceCache.onLoad(subscriber);
    },
    onChange(subscriber) {
        return invoiceCache.onChange(subscriber);
    },
};

export function renderPopupButton() {
    render(<PayLaterButton />, document.getElementById('popup-button-container'));
}
// export function renderButton() {
//     render(<InvoiceButton invoiceId="82384" />, document.getElementById('button-container'));
// }
