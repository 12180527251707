import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useMatch } from 'react-router-dom';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import { useDataContext } from '../../AppContext';
// import { AccountBalanceWallet } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    action: {
        '&.Mui-selected': {
            color: theme.palette.secondary.main,
        },
    },
}));

export const SidebarBottomNavigation = () => {
    const classes = useStyles();
    const match = useMatch('/:id');
    const navigate = useNavigate();
    const [{ products }] = useDataContext();

    return (
        <Box>
            <BottomNavigation
                value={match?.params.id}
                onChange={(_event, newValue) => {
                    navigate(`/${newValue}`);
                }}
                showLabels
            >
                {/* {products.fundnow && (
                    <BottomNavigationAction
                        label="Wallet"
                        value="0"
                        id="btn-nav-wallet"
                        icon={<AccountBalanceWallet />}
                        className={classes.action}
                    />
                )} */}
                {products.fundnow && (
                    <BottomNavigationAction
                        label="FundNow"
                        value="1"
                        id="btn-nav-fundnow"
                        icon={<DonutLargeIcon />}
                        className={classes.action}
                    />
                )}
                {products.paylater && (
                    <BottomNavigationAction
                        label="PayLater"
                        value="2"
                        id="btn-nav-paylater"
                        icon={<TimelapseIcon />}
                        className={classes.action}
                    />
                )}
                {products.ipaylater && (
                    <BottomNavigationAction
                        label="PayLater"
                        value="paylater"
                        id="btn-nav-ipaylater"
                        icon={<TimelapseIcon />}
                        className={classes.action}
                    />
                )}
                {products.drawdown && (
                    <BottomNavigationAction
                        label="DrawDown"
                        value="3"
                        id="btn-nav-drawdown"
                        icon={<ControlPointIcon />}
                        className={classes.action}
                    />
                )}
            </BottomNavigation>
        </Box>
    );
};
