import { useRef } from 'react';
import { Button, Grid, Typography, Box, useMediaQuery } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { LinkButton } from '@lendica/components';

import { useAppContext } from '../../../../../AppContext';

import { Guide, GuideStep, ScrollArea } from '../../../../components';
import { settings } from '../../../../../settings';

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 30,
        boxShadow: 'none',
    },
}));

export const Marketing = ({ containerRef, headerRef, setAnchorEl }) => {
    const {
        appState: { userAccessType },
    } = useAppContext();

    const classes = useStyles();

    const scrollToRef = useRef();

    const match = useMediaQuery(settings.stickyHeaderMediaQuery);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box mt={-4} sx={{ width: '70%', zIndex: 20 }}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    Activate now to instantly access flexible working capital.
                </Typography>
            </Box>
            <Grid justifyContent="space-between" container component={Box} mt={4}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClick}
                        disabled={userAccessType === 'VIEW_ONLY'}
                        className={classes.button}
                    >
                        Unlock credit
                    </Button>
                </Grid>
                <Grid item>
                    <ScrollArea
                        title="See How it Works"
                        to={scrollToRef}
                        offset={match ? headerRef : undefined}
                        container={containerRef}
                    />
                </Grid>
            </Grid>
            <Box mt={8}>
                <Grid justifyContent="flex-end" container component={Box} ref={scrollToRef}>
                    <Box sx={{ width: '70%', textAlign: 'right' }}>
                        <Typography variant="h5" color="textPrimary" component="h5">
                            Withdraw at anytime to fund your business growth.
                        </Typography>
                    </Box>
                </Grid>
                <Grid justifyContent="center" container component={Box} mt={2}>
                    <img
                        src="https://static.golendica.com/drawdown_mkt-FGDZ6FQ2.png"
                        width="85%"
                        alt="drawdown example screens"
                    />
                </Grid>
            </Box>
            <Box mt={8}>
                <Guide title="How it works">
                    <GuideStep
                        stepNumber="1"
                        title="Activate DrawDown"
                        body={
                            <>
                                Provide basic information to unlock up to $2,000,000 in working
                                capital.{' '}
                                {userAccessType === 'ADMIN' && (
                                    <LinkButton
                                        variant="body2"
                                        color="secondary"
                                        onClick={handleClick}
                                    >
                                        Activate Now
                                    </LinkButton>
                                )}
                            </>
                        }
                    />
                    <GuideStep
                        stepNumber="2"
                        title="Draw Funds"
                        body="Choose the amount of funds you need to help your business grow."
                    />
                    <GuideStep
                        stepNumber="3"
                        title="Simple Payback"
                        body="Funds are paid back on a weekly or monthly basis over six or twelve months. Pay back early without any penalty!"
                    />
                </Guide>
            </Box>
            <Box mt={8}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    Questions?
                </Typography>
                <Grid container justifyContent="space-between" component={Box} mt={2}>
                    <Grid item>
                        <Typography variant="h6" color="textPrimary">
                            Schedule a meeting with us
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            href="https://calendly.com/lendica/intro"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.button}
                        >
                            Schedule
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {/* <Box mt={8}>
                <Typography variant="caption">
                    *By clicking "ACTIVATE NOW" or "UNLOCK CREDIT" you hereby consent to share data
                    with Lendica Corp. to assist in providing lending related services.
                </Typography>
            </Box> */}
        </>
    );
};
