import { useNavigate } from 'react-router-dom';
import { financial } from '@lendica/utils';

export const usePayEarlyReview = ({ selectedWithdrawals, summary, pay }) => {
    const navigate = useNavigate();

    const amount = () => {
        return financial(
            selectedWithdrawals.reduce(
                (prevVal, currItem) => prevVal + currItem.withdrawal_early_payoff_amount,
                0
            )
        );
    };

    const availableCredit = () => {
        const { credit_available } = summary;
        let credit = selectedWithdrawals.reduce((prevVal, currItem) => {
            return prevVal + currItem.withdrawal_early_payoff_principal;
        }, 0);

        return financial(credit_available + credit);
    };

    const handleConfirm = async () => {
        try {
            await pay(selectedWithdrawals.map(({ withdrawal_id }) => withdrawal_id));
            navigate('/3/payment/success');
        } catch {
            navigate('/3/payment/failure');
        }
    };

    return {
        amount: amount(),
        availableCredit: availableCredit(),
        handleConfirm,
    };
};
