import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    IconButton,
    Box,
    Typography,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetApp } from '@mui/icons-material';
import { DrawDownContainer } from '../../DrawDownContainer';
import { useActivity } from './useActivity';

import { Header, Body } from '../../../../components';
import {
    currencyFormatter,
    percentageFormatter,
    stringFormatter,
    dateFormatter,
} from '@lendica/utils';

const useStyles = makeStyles(theme => ({}));

const getTransactionDetails = (activity, status) => {
    switch (activity.activity_type) {
        case 0:
            return [
                {
                    label: 'Category',
                    value: 'Withdrawal',
                },
                {
                    label: 'Credit Withdrawn',
                    value: currencyFormatter(activity?.activity_amount ?? ''),
                },
                {
                    label: 'Origination Fee',
                    value: percentageFormatter(activity?.origination_fee_percentage ?? ''),
                },
                {
                    label: 'Amount Funded',
                    value: currencyFormatter(activity?.net_funded_amount ?? ''),
                },
                {
                    label: 'Factor Rate',
                    value: activity?.factor_rate ?? '',
                },
                {
                    label: 'Date',
                    value: dateFormatter(activity?.activity_date ?? ''),
                },
                {
                    label: 'Status',
                    value: stringFormatter(status),
                },
                // {
                //     label: 'Bank Account',
                //     value: '',
                // },
                {
                    label: 'Transaction Type',
                    value: 'Deposit ACH',
                },
                {
                    label: 'Withdrawal ID',
                    value:
                        activity?.withdrawal_id?.slice(0, 8) ??
                        activity?.activity_id?.slice(0, 8) ??
                        '',
                },
            ];
        case 1:
            return [
                {
                    label: 'Category',
                    value: 'Payment',
                },
                {
                    label: 'Amount',
                    value: currencyFormatter(activity?.activity_amount ?? ''),
                },
                {
                    label: 'Payment Type',
                    value: stringFormatter(activity?.payment_type ?? ''),
                },
                {
                    label: 'Date',
                    value: dateFormatter(activity?.activity_date ?? ''),
                },
                {
                    label: 'Status',
                    value: stringFormatter(status),
                },
                // {
                //     label: 'Bank Account',
                //     value: '',
                // },
                {
                    label: 'Transaction Type',
                    value: 'Deposit ACH',
                },
                {
                    label: 'Withdrawal ID',
                    value: activity?.withdrawal_id?.slice(0, 8) ?? '',
                },
            ];
    }
};

export const TransactionDetails = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { selectedActivity } = DrawDownContainer.useContainer();
    const { getActivityStatus } = useActivity(
        selectedActivity.activity_type,
        selectedActivity.status
    );

    const transactionDetail = getTransactionDetails(selectedActivity, getActivityStatus());

    return (
        <>
            <Header
                title="Transaction Details"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('/3/activities')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
                rightActions={
                    <IconButton size="small" onClick={() => window.print()}>
                        <GetApp />
                    </IconButton>
                }
            />
            <Body display="flex" flexDirection="column">
                <Box p={4} pt={5}>
                    <Typography variant="h5">
                        Transaction&nbsp;No.{selectedActivity?.activity_id?.slice(0, 8) ?? ''}
                    </Typography>
                    <Box mt={3}>
                        <List>
                            {transactionDetail.map((item, index) => (
                                <React.Fragment key={index}>
                                    <ListItem disableGutters>
                                        <ListItemText primary={item.label} />
                                        <ListItemSecondaryAction>
                                            <Typography variant="caption" color="textSecondary">
                                                {item.value}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {index !== transactionDetail.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Body>
        </>
    );
};
