import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetApp } from '@mui/icons-material';

import { Header, Body } from '../../../../components';
import { SummaryList, Toolbar, BottomButton } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';
import { WithdrawalBody } from './WithdrawalBody';
// import { BottomButton } from '../../BottomButton';
// import { Toolbar } from '../../Toolbar';
import { PriceFormat } from '../../../../components/PriceFormat';
import { currencyFormatter, dateFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    toolbar: {
        backgroundColor: theme.palette.secondary.light,
    },
    confirmButton: {
        width: 250,
        height: 42,
        borderRadius: 30,
    },
    bold: {
        fontWeight: 800,
        display: 'flex',
    },
    completed: {
        color: theme.palette.action.active,
        marginBottom: theme.spacing(1),
    },
    bottomButton: {
        width: '100%',
        borderRadius: 20,
        height: 36,
        padding: 0,
        boxShadow: 'none',
    },
}));

const getSummary = withdrawalData => {
    return [
        {
            label: 'Transaction No.',
            value: withdrawalData?.id?.slice(0, 8) ?? '',
        },
        {
            label: 'Category',
            value: 'Withdrawal',
        },
        {
            label: 'Amount',
            value: currencyFormatter(withdrawalData?.withdrawal_amount) ?? '',
        },
        {
            label: 'Date',
            value: dateFormatter(withdrawalData?.withdrawal_date ?? Date()),
        },
        // {
        //     label: 'Bank Account',
        //     value: 'Capital One - ****1234',
        // },
        {
            label: 'Transaction Type',
            value: 'Deposit ACH',
        },
        {
            label: 'Withdrawal ID',
            value: withdrawalData?.id.slice(0, 8) ?? '',
        },
    ];
};

export const WithdrawalSuccess = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { withdrawalData, updateDrawDown } = DrawDownContainer.useContainer();

    const handleGoBack = () => {
        navigate('/3');
        updateDrawDown();
    };

    return (
        <>
            <Header
                title="Withdrawal Confirmation"
                showLogo={false}
                showClose={false}
                leftActions={
                    <Button onClick={handleGoBack} className={classes.button}>
                        Done
                    </Button>
                }
                rightActions={
                    <IconButton size="small" onClick={() => window.print()}>
                        <GetApp />
                    </IconButton>
                }
            />
            {/* {isLoading && <Loader />} */}
            {/* {!isLoading && (
                <> */}
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column" flex="0 0 auto">
                        <Typography variant="body2" className={classes.completed}>
                            The transaction will be completed within a few business days
                        </Typography>
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            color="textSecondary"
                        >
                            Withdrawal Successful!
                        </Typography>
                        <Box my={3}>
                            <svg
                                width="64"
                                height="65"
                                viewBox="0 0 64 65"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M58.2531 13.9884L28.1338 44.4417L16.9413 33.135L20.6633 29.375L28.1338 36.9217L54.5311 10.255L58.2531 13.9884ZM52.4193 27.4284C52.7625 28.9484 52.9736 30.5484 52.9736 32.175C52.9736 43.9617 43.5234 53.5084 31.8558 53.5084C20.1882 53.5084 10.738 43.9617 10.738 32.175C10.738 20.3884 20.1882 10.8417 31.8558 10.8417C36.0266 10.8417 39.8806 12.0684 43.1539 14.175L46.9551 10.335C42.6787 7.29503 37.4784 5.50836 31.8558 5.50836C17.2845 5.50836 5.4585 17.455 5.4585 32.175C5.4585 46.895 17.2845 58.8417 31.8558 58.8417C46.4271 58.8417 58.2531 46.895 58.2531 32.175C58.2531 29.0017 57.6724 25.9617 56.6693 23.135L52.4193 27.4284Z"
                                    fill="#4CAF50"
                                />
                            </svg>
                        </Box>
                        <Box textAlign="center">
                            <Typography variant="caption" color="textSecondary">
                                Credit Withdrawn
                            </Typography>

                            <Typography variant="h4" component={Box} className={classes.bold}>
                                <Typography variant="h5" component="span" className={classes.bold}>
                                    $
                                </Typography>
                                <PriceFormat
                                    value={withdrawalData?.withdrawal_amount}
                                    noDollar
                                    decimalScale={2}
                                />
                            </Typography>
                        </Box>
                    </Box>
                    <WithdrawalBody />
                    <Box mt={3}>
                        <SummaryList data={getSummary(withdrawalData)} />
                    </Box>
                </Box>
            </Body>
            <Toolbar
                action={
                    <BottomButton
                        leftActions={
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.bottomButton}
                                onClick={() => {
                                    navigate('/3/payment');
                                    updateDrawDown();
                                }}
                            >
                                see future payments
                            </Button>
                        }
                        rightActions={
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.bottomButton}
                                onClick={handleGoBack}
                            >
                                Ok, Take me back
                            </Button>
                        }
                    />
                }
            />
            {/* </>
            )} */}
        </>
    );
};
