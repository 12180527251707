import { useMemo } from 'react';
import { currencyFormatter, percentageFormatter } from '@lendica/utils';

const epochs = [
    ['year', 31536000],
    ['month', 2592000],
    ['day', 86400],
    ['hour', 3600],
    ['minute', 60],
    ['second', 1],
];

const getDuration = timeAgoInSeconds => {
    if (timeAgoInSeconds === 0) {
        return {
            interval: 1,
            epoch: 'second',
        };
    }
    for (let [name, seconds] of epochs) {
        const interval = Math.floor(timeAgoInSeconds / seconds);
        if (interval >= 1) {
            return {
                interval: interval,
                epoch: name,
            };
        }
    }
};

export const timeAgo = date => {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
    const { interval, epoch } = getDuration(timeAgoInSeconds);
    const suffix = interval === 1 ? '' : 's';
    return `${interval} ${epoch}${suffix} ago`;
};

// is valid object or array data
export const isVOD = obj => {
    return obj && typeof obj === 'object' && Object.keys(obj).length && Object.keys(obj).length > 0;
};

export const isAllVOD = objArr => {
    return objArr.every(isVOD);
};

export const dataTypeToValue = (dtype, value) => {
    // pass in a datatype and value and return
    // the right value
    if (dtype == 'str') {
        return value;
    } else if (dtype == 'perc') {
        return percentageFormatter(value);
    } else if (dtype == 'curr') {
        return currencyFormatter(value);
    } else {
        return value;
    }
};

const getStatusLabel = statusCode => {
    switch (statusCode) {
        case 0:
            return 'Not Paid';
        case 1:
            return 'Pending Client';
        case 2:
            return 'Pending Delivery';
        case 3:
            return 'Delivered';
        case 4:
            return 'Completed';
        default:
            return 'Not Available';
    }
};

const getActionLabel = (actionCode, product) => {
    switch (actionCode) {
        case 0:
            return product === 'fundnow' ? 'Get Paid Now' : 'Send Invite';
        case 1:
            return 'Send Reminder';
        case 2:
            return 'Confirm Delivery';
        case 3:
            return 'See Progress';
        case 4:
            return 'View Details';
        default:
            return 'Not Available';
    }
};

export const useTableData = (product, invoices, actionHandler = null) => {
    return useMemo(
        () =>
            isVOD(invoices)
                ? {
                      columns: [
                          {
                              field: 'id',
                              headerName: 'Id',
                              align: 'left',
                              width: '12%',
                          },
                          {
                              field: 'totalPrice',
                              headerName: 'Amount',
                              align: 'right',
                              width: '22%',
                              formatter: currencyFormatter,
                          },
                          {
                              field: 'paymentStatus',
                              headerName: 'Status',
                              align: 'left',
                              width: '22%',
                              formatter: getStatusLabel,
                          },
                          {
                              field: 'action',
                              headerName: 'Action',
                              type: 'action',
                              align: 'right',
                              width: '22%',
                          },
                      ],
                      rows: invoices.map(item => ({
                          id: item.order_id,
                          totalPrice: item.total_price,
                          paymentStatus: item.status,
                          action: {
                              text: getActionLabel(item.action, product),
                              handler: actionHandler && actionHandler(item.order_id),
                          },
                      })),
                  }
                : undefined,
        [invoices]
    );
};
