import { createTheme } from '@mui/material';
import deepmerge from 'deepmerge';

import { theme } from '@lendica/theme';

export const getTheme = () =>
    createTheme(
        deepmerge(theme, {
            typography: {
                // Making sure that host page font size does not affect widget font size
                // More info about how resulting MUI font size gets calculated https://material-ui.com/customization/typography/#font-size
                htmlFontSize: +getComputedStyle(document.documentElement).fontSize.replace(
                    'px',
                    ''
                ),
            },
            components: {
                MuiModal: {
                    defaultProps: { disablePortal: true },
                },
            },
        })
    );
