import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Overlay, OverlayFieldset } from '@lendica/components';
import { Loader, ProgressTimeline } from '../../components';

import { useAppContext, useDataContext } from '../../../AppContext';
import { seeProgress } from '../../../api';

export const FNSeeProgress = ({ invoiceId }) => {
    const {
        appState: { fnIsLoading },
        setAppState,
    } = useAppContext();
    const [data, setData] = useDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
                const invoiceData = await seeProgress(
                    {
                        lendica_token: data?.company?.lendica_token ?? null,
                        invoice_id: invoiceId,
                    },
                    'fundnow'
                );
                setData(currentData => ({
                    ...currentData,
                    details: {
                        ...currentData.details,
                        ...invoiceData,
                    },
                }));
            } catch (error) {
                setAppState(currentState => ({
                    ...currentState,
                    snackbar: {
                        open: true,
                        message: `We couldn't load the invoice, please try again later. ${error}`,
                    },
                }));
            } finally {
                setAppState(currentState => ({ ...currentState, fnIsLoading: false }));
            }
        })();
    }, [invoiceId]);

    const handleOverlayClose = () => {
        navigate('/1');
    };

    return fnIsLoading ? (
        <Loader />
    ) : (
        <Overlay title="Payment Progress" handleClose={handleOverlayClose}>
            <OverlayFieldset
                subtitle={`Invoice No. ${data?.details?.invoice?.order_id ?? 'Not Avalaible'}`}
            />
            <ProgressTimeline schedules={data?.details?.servicing_schedule ?? null} />
        </Overlay>
    );
};
