import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Overlay,
    OverlayFieldset,
    OverlayKeypoint,
    OverlayAction,
    FootNote,
} from '@lendica/components';
import { Loader } from '../../components';

import { currencyFormatter } from '@lendica/utils';

import { initializeInvoice, invoiceActivate } from '../../../api';

import { useAppContext, useDataContext } from '../../../AppContext';

export const FNInvoiceDetails = ({ invoiceId }) => {
    const {
        appState: { fnIsLoading },
        setAppState,
        updateData,
    } = useAppContext();
    const [data, setData] = useDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
                const invoiceData = await initializeInvoice(
                    {
                        lendica_token: data?.company?.lendica_token ?? null,
                        invoice_id: invoiceId,
                    },
                    'fundnow'
                );
                setData(currentData => ({
                    ...currentData,
                    details: {
                        ...currentData.details,
                        ...invoiceData,
                    },
                }));
            } catch (error) {
                setAppState(currentState => ({
                    ...currentState,
                    snackbar: {
                        open: true,
                        message: `We couldn't load the invoice, please try again later. ${error}`,
                    },
                }));
            } finally {
                setAppState(currentState => ({ ...currentState, fnIsLoading: false }));
            }
        })();
    }, [invoiceId]);

    const handleSubmit = async () => {
        try {
            setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
            await invoiceActivate({
                lendica_token: data?.company?.lendica_token ?? null,
                invoice_id: data?.details?.invoice?.invoice_id ?? null,
            });
            navigate(`/1`);
            await updateData();
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't activate this invoice, please try again later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({ ...currentState, fnIsLoading: false }));
        }
    };

    const handleOverlayClose = () => {
        navigate(`/1`);
    };

    return fnIsLoading ? (
        <Loader />
    ) : (
        <Overlay title="Invoice Details" handleClose={handleOverlayClose}>
            <OverlayFieldset
                subtitle={`Invoice No. ${data?.details?.invoice?.order_id ?? 'Not Available'}`}
            >
                <OverlayKeypoint
                    summary="Invoice total"
                    details={currencyFormatter(
                        data?.details?.invoice?.total_price ?? 'Not Available'
                    )}
                />
                <OverlayKeypoint
                    summary="Funds you'll receive upfront"
                    details={currencyFormatter(
                        data?.details?.invoice?.funds_upfront ?? 'Not Available'
                    )}
                />
                <OverlayKeypoint
                    summary="Fees you'll pay*"
                    details={currencyFormatter(data?.details?.invoice?.fees ?? 'Not Available')}
                />
            </OverlayFieldset>
            <FootNote
                lines={[
                    '*Based on your customer paying this invoice in full and on-time in 30 days.',
                ]}
            />
            <OverlayAction actionId="btn-invoice-details-action" action="Accept" onClick={handleSubmit} />
        </Overlay>
    );
};
