import { useParams } from 'react-router-dom';

import { useDataContext } from '../../../AppContext';
import { Loader } from '../../components';

import { PLConfirmEmail } from './PLConfirmEmail';
import { PLSeeProgress } from './PLSeeProgress';

export const PayLaterOverlay = () => {
    const [data] = useDataContext();
    const { orderId } = useParams();

    const findInvoice = () => {
        return data?.paylater?.invoices?.find(invoice => invoice.order_id === orderId);
    };

    const getOverlay = () => {
        const invoiceData = findInvoice();
        switch (invoiceData?.action ?? 9) {
            case 0:
            case 1:
            case 2:
                return <PLConfirmEmail invoiceData={invoiceData} />;
            case 3:
            case 4:
                return <PLSeeProgress invoiceId={invoiceData.invoice_id} />;
            case 9:
                return <Loader />;
            default:
                break;
        }
    };

    return getOverlay();
};
