import { useNavigate } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Header, Body } from '../../components';
import { SettingsListGroup } from './SettingsList';
import { useData } from './useSettings';

const getData = navigate => {
    return [
        {
            subheader: '',
            listData: [
                {
                    text: 'Owner',
                    details: 'Example Name',
                    disableAction: true,
                },
                {
                    text: 'Billing Address',
                    details: 'Example Address',
                    disableAction: true,
                },
                {
                    text: 'Email',
                    details: 'example@gmail.com',
                    disableAction: true,
                },
                {
                    text: 'Phone',
                    details: '+1 (123) 456-7890',
                    disableAction: true,
                },
            ],
        },
    ];
};

export const Contact = () => {
    const navigate = useNavigate();
    const data = useData(getData, navigate);

    return (
        <>
            <Header
                title="Contact"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate(-1)}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
            />
            <Body display="flex" flexDirection="column">
                {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                        <Box key={`group-${index}`} mt={4}>
                            <SettingsListGroup {...item} />
                        </Box>
                    ))}
            </Body>
        </>
    );
};
