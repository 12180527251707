import { useEffect, useRef, useState } from 'react';
import { Drawer, Box, Snackbar, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { useAppContext, useDataContext } from '../AppContext';
import { settings } from '../settings';

import { SplashScreen, NetworkErrorFallback } from '@lendica/components';
import { FundNow, PayLater, DrawDown, Settings, Contact } from './views';
import { FundNowOverlay } from './views/FundNow/FundNowOverlay';
import { FNActivate } from './views/FundNow/FNActivate';
import { PayLaterOverlay } from './views/PayLater/PayLaterOverlay';
import { PLActivate } from './views/PayLater/PLActivate';
import { ApplicationForm } from './ApplicationForm';
import { StatusPage, SidebarBottomNavigation, Header, Loader, Body } from './components';
// import { WalletView } from '../../../../features/wallet/src/views/WalletView';
// import Popup from './components/Popup';
// import PDFPopup from './components/PDFPopup';
import { IPayLater } from './views/IPayLater';

const useStyles = makeStyles(theme => {
    const mq = settings.fullscreenMediaQuery(theme);
    return {
        root: {
            width: settings.sidebarWidth,
            borderLeft: `1px solid ${theme.palette.divider}`,
            [mq]: {
                width: '100%',
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer,
            color: theme.palette.common.white,
            position: 'absolute',
        },
        snackbarButton: {
            color: theme.palette.success.main,
        },
        content: {
            overflowY: 'auto',
            flexGrow: 1,
        },
        bottomNavigation: {
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
        },
    };
});

export const Sidebar = () => {
    const {
        appState: { isOpen, appIsLoading, fnIsLoading, plIsLoading, snackbar },
        setAppState,
        toggleIsOpen,
        updateData,
    } = useAppContext();

    const [popupOpen, setPopupOpen] = useState(false);

    const closeHandler = () => {
        setPopupOpen(false);
    };
    const openHandler = () => {
        setPopupOpen(true);
    };

    const [data] = useDataContext();

    const classes = useStyles();

    const containerRef = useRef();
    const headerRef = useRef();

    const navigate = useNavigate();

    const handleSnackbarClose = async (event, reason) => {
        if (reason === 'clickaway' && event.path && event.path[0]?.id === 'lendica-logo-blue') {
            return;
        }
        try {
            updateData();
        } finally {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: false,
                    message: '',
                },
            }));
        }
    };

    const tryAgain = async () => {
        try {
            setAppState(currentState => ({
                ...currentState,
                appIsLoading: true,
            }));
            await updateData();
        } finally {
            setAppState(currentState => ({
                ...currentState,
                appIsLoading: false,
            }));
        }
    };

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            localStorage.setItem('data', JSON.stringify(data));
        }, [data]);
    }

    // const navigateTo = data.products?.fundnow ? '/1' : data.products?.paylater ? '/2' : '/3';

    return (
        <Drawer
            id="sidebar-drawer"
            anchor="right"
            open={isOpen}
            onClose={() => toggleIsOpen()}
            BackdropProps={{ invisible: true }}
            keepMounted
            elevation={0}
            PaperProps={{
                className: classes.root,
            }}
            ModalProps={{ disableEnforceFocus: true }} // https://github.com/mui-org/material-ui/issues/24032
        >
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={<Box sx={{ width: 300 }}>{snackbar.message}</Box>}
                action={
                    <>
                        <Button
                            className={classes.snackbarButton}
                            size="small"
                            onClick={handleSnackbarClose}
                        >
                            OK
                        </Button>
                    </>
                }
            />
            {appIsLoading && <SplashScreen />}
            {!appIsLoading && !data?.products && (
                <NetworkErrorFallback onClick={() => tryAgain()} />
            )}
            {!appIsLoading && data?.products && (
                <>
                    <Routes>
                        <Route
                            path="/1/*"
                            element={
                                <>
                                    <Header
                                        rightActions={
                                            <IconButton
                                                size="small"
                                                onClick={() => navigate('/settings')}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        ref={headerRef}
                                    />
                                    <Body sx={{ height: '100%' }} ref={containerRef}>
                                        <Routes>
                                            <Route
                                                path="/application/:applicationId/*"
                                                element={<ApplicationForm />}
                                            />
                                            <Route
                                                path="/submitted"
                                                element={
                                                    <StatusPage
                                                        title="Your application is being processed"
                                                        subtitle="We will get back to you soon"
                                                        duration={7}
                                                        actionLabel="Go Back"
                                                        action={() => {
                                                            navigate('/1');
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route path="/offer" element={<FNActivate />} />
                                            <Route
                                                path="/invoice/:orderId"
                                                element={<FundNowOverlay />}
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    // fnIsLoading ? (
                                                    //     <Loader />
                                                    // ) : (
                                                    <FundNow
                                                        containerRef={containerRef}
                                                        headerRef={headerRef}
                                                    />
                                                    // )
                                                }
                                            />
                                        </Routes>
                                    </Body>
                                </>
                            }
                        />
                        <Route
                            path="/2/*"
                            element={
                                <>
                                    <Header
                                        rightActions={
                                            <IconButton
                                                size="small"
                                                onClick={() => navigate('/settings')}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        ref={headerRef}
                                    />
                                    <Body ref={containerRef}>
                                        <Routes>
                                            <Route path="/offer" element={<PLActivate />} />
                                            <Route
                                                path="/invoice/:orderId"
                                                element={<PayLaterOverlay />}
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    // plIsLoading ? (
                                                    //     <Loader />
                                                    // ) : (
                                                    <PayLater
                                                        containerRef={containerRef}
                                                        headerRef={headerRef}
                                                    />
                                                    // )
                                                }
                                            />
                                        </Routes>
                                    </Body>
                                </>
                            }
                        />
                        {/* 
                        <Route
                            path="/0/*"
                            element={
                                <>
                                    <Header
                                        rightActions={
                                            <IconButton
                                                size="small"
                                                onClick={() => navigate('/settings')}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        ref={headerRef}
                                    />
                                    <Body ref={containerRef}>
                                        
                                        <Routes>
                                            <Route path="/account/summary" element={<Popup />} />
                                            <Route
                                                path="*"
                                                element={
                                                    <WalletView callback={openHandler}/>
                                                }
                                            />
                                        </Routes>
                                    </Body>        
                                </>
                            }
                        />   */}
                        <Route path="/3/*" element={<DrawDown />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/settings/contact" element={<Contact />} />
                        <Route path="/paylater/*" element={<IPayLater />} />
                        <Route
                            path="/*"
                            element={
                                <Navigate
                                    to={
                                        data.products?.fundnow
                                            ? '/1'
                                            : data.products?.paylater
                                            ? '/2'
                                            : data.products?.ipaylater
                                            ? '/paylater'
                                            : '/3'
                                    }
                                />
                            }
                        />
                    </Routes>

                    {Object.values(data.products).filter(item => item).length > 1 && (
                        <SidebarBottomNavigation />
                    )}
                </>
            )}

            {/* <Box sx={{width:"1024px", height:"100%", display: "flex", flexDirection: "row"}}>
                <AccountSummaryView/>
            </Box> */}

            {/* {popupOpen && <Popup minimized={true} closeHandler={closeHandler} open={popupOpen} title='Account Summary'/>}   */}
            {/* {popupOpen && <PDFPopup closeHandler={closeHandler} title='View Invoice'/>} */}
        </Drawer>
    );
};
