import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Loader, Body } from '../../components';

import { useApi } from './plOnboardingAPI';
import { useAppContext, useDataContext } from '../../../AppContext';

export const IPayLaterApplyLoader = () => {
    const {
        appState: { environment },
    } = useAppContext();
    const [
        {
            company: { lendica_token },
        },
    ] = useDataContext();
    const navigate = useNavigate();
    const plOnboardingAPI = useApi(environment);

    useEffect(() => {
        (async function () {
            try {
                navigate(
                    `/paylater/application/${
                        (await plOnboardingAPI.createApplication(lendica_token)).data.application_id
                    }`
                );
            } catch (e) {
                navigate('/paylater');
            }
        })();
    }, []);

    return (
        <>
            <Header />
            <Body>
                <Loader />
            </Body>
        </>
    );
};
