import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import { SplashScreen } from '@lendica/components';
import { Header, Loader, Body, StatusPage } from '../../components';
import { DrawDownContainer } from './DrawDownContainer';
import {
    WithdrawalConfirmation,
    Withdrawal,
    Application,
    Activities,
    PayEarlyReview,
    PaymentSuccess,
    PaymentFailure,
    Payment,
    PayEarly,
    Main,
} from './views';
import { TransactionDetails } from './views/Activities/TransactionDetails';
import { WithdrawalFailure } from './views/Withdrawal/WithdrawalFailure';
import { WithdrawalSuccess } from './views/Withdrawal/WithdrawalSuccess';

const DrawDownRouter = () => {
    const { data, isLoading } = DrawDownContainer.useContainer();
    const navigate = useNavigate();

    return (
        <>
            {(!data || isLoading) && (
                <>
                    <Header />
                    <Body>
                        <Loader />
                    </Body>
                </>
                // <SplashScreen start={isLoading} />
            )}
            {data && !isLoading && (
                <Routes>
                    <Route path="/*" element={<Main />} />
                    <Route path="/application/:applicationId/*" element={<Application />} />
                    <Route
                        path="/submitted"
                        element={
                            <StatusPage
                                title="Your application is being processed"
                                subtitle="We will get back to you soon"
                                duration={7}
                                actionLabel="Go Back"
                                action={() => {
                                    navigate('/3');
                                }}
                            />
                        }
                    />
                    <Route path="/withdrawal" element={<Withdrawal />} />
                    <Route path="/withdrawal/review" element={<WithdrawalConfirmation />} />
                    <Route path="/withdrawal/success" element={<WithdrawalSuccess />} />
                    <Route path="/withdrawal/failure" element={<WithdrawalFailure />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/payment/review" element={<PayEarlyReview />} />
                    <Route path="/payment/success" element={<PaymentSuccess />} />
                    <Route path="/payment/failure" element={<PaymentFailure />} />
                    <Route path="/payment/pay-early" element={<PayEarly />} />
                    <Route path="/activities" element={<Activities />} />
                    <Route path="/activities/detail" element={<TransactionDetails />} />
                </Routes>
            )}
        </>
    );
};

export const DrawDown = () => (
    <DrawDownContainer.Provider>
        <DrawDownRouter />
    </DrawDownContainer.Provider>
);
