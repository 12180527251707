import { AxiosClient } from './axios';

let axios;
export const createInstance = async env => {
    axios = AxiosClient.create(env);
};

/**
 * statusCode - statusLabel - actionLabel - [behind the scene actions] (api route)
 *
 * FundNow
 * 0 - Not paid - Get paid now - [a. submitting seller/buyer emails] (/pod/initialize_invoice)
 *                             - [b. after seller application process] (/pod/fundnow/invoice_activate)
 * 1 - (Not implemented)
 * 2 - Pending delivery - Confirm delivery - [a. user click on action link] (/pod/confirm_email)
 *                                         - [b. submitting seller/buyer emails and term length] (/pod/action_confirm_delivery)
 * 3 - Delivered - See progress - [user click on action link] (/pod/fundnow/action_see_progress)
 * 4 - Completed - View details - [user click on action link] (/pod/fundnow/action_see_progress)
 *
 * PayLater
 * 0 - Not paid - Send invite  - [a. user click on action link] (/pod/confirm_email)
 *                             - [b. submitting seller/buyer emails] (/pod/paylater/initialize_invoice)
 * 1 - Pending client - Send reminder - [a. user click on action link] (/pod/confirm_email)
 *                                    - [b. submitting seller/buyer emails] (/pod/paylater/action_send_reminder)
 * 2 - Pending delivery - Confirm delivery - [a. user click on action link] (/pod/confirm_email)
 *                                         - [b. submitting seller/buyer emails] (/pod/action_confirm_delivery)
 * 3 - Delivered - See progress - [user click on action link] (/pod/paylater/action_see_progress)
 * 4 - Completed - View details - [user click on action link] (/pod/paylater/action_see_progress)
 *
 */

/**
 * Actions for both products
 */

// init and update
export const initUser = credentials => {
    return postRequest('/pod/init', credentials);
};

// refresh invoices
export const refreshInvoices = lendica_token => {
    return postRequest('/pod/refresh_invoices', { lendica_token });
};

// activate offer -> returns approval
export const activateOffer = (lendica_token, product) => {
    return postRequest(`/pod/${product}/activate_offer`, { lendica_token });
};

// activate submit
export const activateSubmit = ({ lendica_token, approval }, product) => {
    return postRequest(`/pod/${product}/activate_submit`, { lendica_token, approval });
};

// fundnow - get paid now, paylater - invite
export const initializeInvoice = (props, product) => {
    return postRequest(`/pod/${product}/initialize_invoice`, { ...props });
};

// send reminder
export const sendReminder = (props, product) => {
    return postRequest(`/pod/${product}/action_send_reminder`, { ...props });
};

// confirm email
export const confirmEmail = ({ lendica_token, invoice_id }) => {
    return postRequest(`/pod/confirm_email`, {
        lendica_token,
        invoice_id,
    });
};

// confirm delivery
export const confirmDelivery = ({
    lendica_token,
    invoice_id,
    buyer_email,
    seller_email,
    term_length = null,
}) => {
    return postRequest(`/pod/action_confirm_delivery`, {
        lendica_token,
        invoice_id,
        buyer_email,
        seller_email,
        term_length,
    });
};

// see progress
export const seeProgress = ({ lendica_token, invoice_id }, product) => {
    return postRequest(`/pod/${product}/action_see_progress`, { lendica_token, invoice_id });
};

/*
 * Actions for FundNow
 */

// fundnow activate apply -> returns application_id, redirects to application
export const activateApply = lendica_token => {
    return postRequest(`/pod/fundnow/activate_apply`, { lendica_token });
};

// fundnow - invoice activate
export const invoiceActivate = ({ lendica_token, invoice_id }) => {
    return postRequest(`/pod/fundnow/invoice_activate`, { lendica_token, invoice_id });
};

/**
 * Base post request
 */

export const postRequest = (url, d) => {
    if (!axios) {
        createInstance();
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                ...d,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                }
                reject(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};
