import React from 'react';
import { IconButton, List, ListItem, ListItemText, ListSubheader, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: props => ({
        background: theme.palette.background.default,
        cursor: props.disableAction ? 'default' : 'pointer',
    }),
    details: {
        textAlign: 'right',
    },
}));

export const SettingsListItem = ({
    text,
    details = '',
    onClick = () => null,
    disableAction = false,
}) => {
    const classes = useStyles({ disableAction });
    return (
        <ListItem button onClick={onClick} className={classes.root}>
            <ListItemText inset primary={text} />
            <ListItemText inset secondary={details} className={classes.details} />
            {!disableAction && (
                <IconButton size="small">
                    <ChevronRightIcon />
                </IconButton>
            )}
        </ListItem>
    );
};

export const SettingsListGroup = ({ subheader, listData }) => {
    return (
        <List
            subheader={
                <ListSubheader inset disableSticky>
                    {subheader}
                </ListSubheader>
            }
        >
            {listData.map((item, index) => (
                <React.Fragment key={`${subheader}-${index}`}>
                    <SettingsListItem {...item} />
                    {index !== listData.length - 1 ? (
                        <Divider variant="inset" component="li" />
                    ) : null}
                </React.Fragment>
            ))}
        </List>
    );
};
