import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export type TagColor = 'error' | 'info' | 'success' | 'warning';

export interface TagProps {
    color?: TagColor;
    label: string;
}

const colorMapping = {
    error: 'error.main',
    info: 'info.light',
    success: 'success.main',
    warning: 'warning.main',
};

export const Tag: FC<TagProps> = ({ color = 'info', label }) => {
    return (
        <Box
            sx={{
                height: 20,
                bgcolor: colorMapping[color],
                px: 0.5,
                borderRadius: 1,
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="caption" color="common.white">
                {label}
            </Typography>
        </Box>
    );
};
