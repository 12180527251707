import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, TextField, Typography, Button } from '@mui/material';
import { Overlay, OverlayFieldset } from '@lendica/components';
import { Loader } from '../../components';

import { confirmEmail, initializeInvoice, sendReminder, confirmDelivery } from '../../../api';

import { useAppContext, useDataContext } from '../../../AppContext';

export const PLConfirmEmail = ({ invoiceData }) => {
    const {
        appState: { plIsLoading },
        setAppState,
    } = useAppContext();
    const [data, setData] = useDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                setAppState(currentState => ({ ...currentState, plIsLoading: true }));
                const contactInfo = await confirmEmail({
                    lendica_token: data?.company?.lendica_token,
                    invoice_id: invoiceData?.invoice_id,
                });
                setData(currentState => ({
                    ...currentState,
                    details: { ...currentState.details, ...contactInfo },
                }));
            } catch (error) {
                setAppState(currentState => ({
                    ...currentState,
                    snackbar: {
                        open: true,
                        message: `We couldn't load the invoice contacts, please try again later. ${error}`,
                    },
                }));
            } finally {
                setAppState(currentState => ({ ...currentState, plIsLoading: false }));
            }
        })();
    }, [invoiceData]);

    const getNextAction = actionCode => {
        switch (actionCode) {
            case 0:
            case 1:
                return payload => initializeInvoice(payload, 'paylater');
            // case 1:
            //     return payload => sendReminder(payload, 'paylater');
            case 2:
                return payload => confirmDelivery(payload);
            default:
                break;
        }
    };

    const handleSubmit = async event => {
        event.preventDefault();
        const nextAction = getNextAction(invoiceData?.action);
        try {
            setAppState(currentState => ({ ...currentState, plIsLoading: true }));
            await nextAction({
                lendica_token: data?.company?.lendica_token ?? null,
                invoice_id: invoiceData?.invoice_id ?? null,
                seller_email: data?.details?.seller_email ?? null,
                buyer_email: data?.details?.buyer_email ?? null,
            });
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message:
                        'Action required - An email is sent to you and/or your customer. Please follow the instructions in your email.',
                },
            }));
            navigate('/2');
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `We couldn't send the email at the moment, please try again later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({ ...currentState, plIsLoading: false }));
        }
    };

    const handleChange = e => {
        setData(currentState => ({
            ...currentState,
            details: {
                ...currentState.details,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const handleOverlayClose = () => {
        navigate('/2');
    };

    return plIsLoading ? (
        <Loader />
    ) : (
        <Overlay
            title={
                invoiceData?.action === 0
                    ? 'Invite your customer to pay later'
                    : invoiceData?.action === 1
                    ? 'Send a reminder to your customer'
                    : `Confirm delivery on invoice ${invoiceData?.order_id}`
            }
            handleClose={handleOverlayClose}
        >
            <form onSubmit={handleSubmit}>
                <OverlayFieldset subtitle={`Invoice Contact Details`}>
                    <Grid item component={Box} py={4} width="70%">
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            align="center"
                            gutterBottom
                        >
                            Confirm your email
                        </Typography>
                        <Typography variant="subtitle1" color="secondary" align="center" paragraph>
                            From: {data?.details?.seller_name ?? ''}
                        </Typography>
                        <TextField
                            type="email"
                            name="seller_email"
                            label="Your Email"
                            value={data?.details?.seller_email ?? ''}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item component={Box} py={4} width="70%">
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            align="center"
                            gutterBottom
                        >
                            Confirm your customer's email
                        </Typography>
                        <Typography variant="subtitle1" color="secondary" align="center" paragraph>
                            To: {data?.details?.buyer_name ?? ''}
                        </Typography>
                        <TextField
                            type="email"
                            name="buyer_email"
                            label="Your Customer's Email"
                            value={data?.details?.buyer_email ?? ''}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                </OverlayFieldset>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    component={Box}
                    my={6}
                >
                    <Grid item>
                        <Button id="btn-invoice-details-action" type="submit" variant="contained" color="secondary">
                            {invoiceData?.action === 0
                                ? 'Send Invite'
                                : invoiceData?.action === 1
                                ? 'Send Reminder'
                                : 'Send Confirmation'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Overlay>
    );
};
