import { forwardRef } from 'react';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { Close as CloseIcon } from '@mui/icons-material';
import { ExitToApp as CloseIcon } from '@mui/icons-material';
import { Logo } from '@lendica/components';

import { useAppContext } from '../../AppContext';
import { settings } from '../../settings';

const useStyles = makeStyles(theme => {
    const mq = settings.fullscreenMediaQuery(theme);
    return {
        grid: {
            height: 46,
        },
        closeButton: {
            display: 'none',
            [mq]: {
                display: 'inline-flex',
            },
        },
    };
});

export const Header = forwardRef(
    ({ leftActions, rightActions, title, showLogo = true, showClose = true }, ref) => {
        const {
            appState: { isHeaderVisible },
            toggleIsOpen,
        } = useAppContext();
        const classes = useStyles();

        return (
            isHeaderVisible && (
                <Box ref={ref}>
                    <Grid
                        className={classes.grid}
                        justifyContent="space-between"
                        alignItems="center"
                        container
                        component={Box}
                        pt={2}
                        px={2}
                    >
                        <Grid item container justifyContent="flex-start" xs={2}>
                            {showLogo && (
                                <Box
                                    flexDirection="column"
                                    justifyContent="center"
                                    display="flex"
                                    height="100%"
                                >
                                    <Link
                                        href="https://home.golendica.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Logo height={18} />
                                    </Link>
                                </Box>
                            )}
                            {leftActions}
                        </Grid>
                        <Grid item container justifyContent="center" xs={8}>
                            <Typography variant="subtitle1" color="textPrimary">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" xs={2}>
                            {rightActions}
                            {showClose && (
                                <IconButton
                                    className={classes.closeButton}
                                    size="small"
                                    onClick={() => toggleIsOpen()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )
        );
    }
);
