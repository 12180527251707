import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    radiusLeft: {
        '&.MuiButton-root': {
            marginLeft: -23,
            borderRadius: '23px 0 0 23px',
        },
    },
    radiusRight: {
        '&.MuiButton-root': {
            borderRadius: '0 23px 23px 0',
        },
    },
    buttonInactive: {
        '&.MuiButton-root': {
            color: theme.palette.action.disabled,
        },
    },
}));
export const PageToggleButton = ({
    startIcon,
    label,
    path,
    radiusDirection = 'right',
    exact = false,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const match = matchPath(location.pathname, {
        path,
        exact,
    });

    return (
        <Button
            startIcon={startIcon}
            variant={match ? 'contained' : undefined}
            color={match ? 'primary' : undefined}
            className={clsx(
                radiusDirection === 'right' ? classes.radiusRight : classes.radiusLeft,
                !match && classes.buttonInactive
            )}
            onClick={() => navigate(path)}
            size="large"
        >
            {label}
        </Button>
    );
};
