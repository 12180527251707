import { useAppContext, useDataContext } from '../../../AppContext';
import { Loader } from '../../components';

import { PayLaterPre } from './PayLaterPre';
import { PayLaterPost } from './PayLaterPost';

export const PayLater = ({ containerRef, headerRef }) => {
    const {
        appState: { plIsLoading, userAccessType },
    } = useAppContext();

    const [data] = useDataContext();

    return plIsLoading ? (
        <Loader />
    ) : data?.paylater?.activated && userAccessType === 'ADMIN' ? (
        <PayLaterPost />
    ) : (
        <PayLaterPre containerRef={containerRef} headerRef={headerRef} />
    );
};
