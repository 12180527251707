import { useState } from 'react';
import { Typography, Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { currencyFormatter } from '@lendica/utils';

import { useAppContext, useDataContext } from '../../../AppContext';
import { SidebarPanel, ConsentPopover } from '../../components';

const useStyles = makeStyles(theme => ({
    header: {
        'color': theme.palette.secondary.light,
        'textAlign': 'center',
        '& > span': {
            fontWeight: theme.typography.fontWeightBold,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 19,
        },
    },
    box: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 8,
    },
}));

export const FNPreHeader = ({ openForm }) => {
    const {
        appState: { userAccessType },
    } = useAppContext();
    const [data] = useDataContext();

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box my={2} py={2} mx={2} className={classes.box}>
                <Typography variant="h5" className={classes.header}>
                    Get paid for invoices <span>now</span>
                </Typography>
            </Box>
            <Box mt={2} px={2}>
                <SidebarPanel
                    buttonLabel="Get Paid Now"
                    buttonId="btn-fn-get-paid-now-01"
                    title="Unpaid Invoices"
                    subtitle="Up to"
                    subtitleAmount={currencyFormatter(
                        data.fundnow?.summary?.invoice_total ?? 50000
                    )}
                    onClick={handleClick}
                    disabled={userAccessType === 'VIEW_ONLY'}
                />
                <ConsentPopover
                    anchorEl={anchorEl}
                    content={`By proceeding you hereby consent to share data with Lendica Corp. to assist in providing lending related services.`}
                    handleClose={handleClose}
                    handleConsent={openForm}
                    actionCancelId="btn-fn-consent-popover-cancel-01"
                    actionProceedId="btn-fn-consent-popover-proceed-01"
                />
            </Box>
        </>
    );
};
