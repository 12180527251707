import { FC, useMemo } from 'react';

import { PieChart } from './PieChart';

const getUsedPercent = (totalCredit: number, available: number) => {
    if (totalCredit === 0) {
        return 0;
    }
    return Math.floor(((totalCredit - available) * 100) / totalCredit);
};

const getAvailablePercent = (totalCredit: number, available: number) => {
    if (totalCredit === 0) {
        return 100;
    }
    return Math.floor((available * 100) / totalCredit);
};

export const UsageChart: FC<{
    total: number;
    available: number;
    color?: string;
    bgColor?: string;
}> = ({ total, available, color = '#2d3192', bgColor = '#ededf0' }) => {

    // const data = useMemo(
    //     () => [
    //         {
    //             label: 'Credit Used',
    //             value: getUsedPercent(total, available),
    //             color: bgColor,
    //         },
    //         {
    //             label: 'Credit Available',
    //             value: getAvailablePercent(total, available),
    //             color: color,
    //             id: 'centered',
    //         },
    //     ],
    //     []
    // );

    const data = [
        {
            label: 'Credit Used',
            value: getUsedPercent(total, available),
            color: bgColor,
        },
        {
            label: 'Credit Available',
            value: getAvailablePercent(total, available),
            color: color,
            id: 'centered',
        },
    ]

    return <PieChart data={data} />;
};
