import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    IconButton,
    Popover,
    Typography,
    Slider,
    Link,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { ArrowDropDown, Info } from '@mui/icons-material';

import { Header, Body } from '../../../../components';
import { Toolbar } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';
import { usePayEarly } from './hooks/usePayEarly';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    totalSaved: {
        fontWeight: 600,
        paddingRight: 56,
    },
    button: {
        color: theme.palette.action.active,
    },
    amountButton: {
        borderRadius: 20,
        minWidth: 20,
        padding: '3px 10px',
        marginRight: theme.spacing(1),
        height: 25,
        fontSize: 13,
        fontWeight: theme.typography.fontWeightRegular,
    },
    label: {
        'backgroundColor': '#757575',
        'paddingLeft': 15,
        'paddingRight': 15,
        'left': 'calc(-50% - 19px)',
        'borderRadius': 4,

        '& > span': {
            backgroundColor: 'inherit',
        },
        '& > span > span': {
            whiteSpace: 'nowrap',
        },
    },
    number: {
        fontSize: 40,
        color: theme.palette.secondary.main,
        fontWeight: 800,
        fontFamily: theme.typography.fontFamily,
    },
    payNowButton: {
        width: '70%',
        height: 42,
        borderRadius: 30,
        boxShadow: 'none',
    },
    dollar: {
        fontWeight: 800,
    },
    withdrawalItemContainer: {
        position: 'relative',
    },
    withdrawalItem: {
        padding: '16px 0',
        paddingLeft: 32,
        position: 'relative',
        left: -32,
        right: 26,
        width: '100%',
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
    },
    paid: {
        'backgroundColor': theme.palette.background.green,
        '& span': {
            color: theme.palette.common.white,
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightSemibold,
        },
    },
    pending: {
        'backgroundColor': theme.palette.background.cardLight,
        '& span': {
            color: theme.palette.secondary.dark,
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightSemibold,
        },
    },
    tooltip: {
        padding: '4px 8px',
        fontSize: 10,
        backgroundColor: 'rgba(97, 97, 97, 0.9)',
        width: 170,
        color: theme.palette.common.white,
    },
    infoIconButton: {
        'padding': 0,
        'paddingLeft': 6,
        '& svg': {
            fontSize: '1.1rem',
        },
    },
    checkCircleIconButton: {
        'color': theme.palette.background.green,
        'position': 'absolute',
        'right': -56,
        'top': 0,
        'zIndex': 1,
        '& svg': {
            fontSize: '2rem',
        },
    },
}));

const CustomAccordion = withStyles({
    root: {
        'borderTop': '1px solid #D9D9E5',
        'borderBottom': '1px solid #D9D9E5',
        'boxShadow': 'none',
        'marginBottom': 24,
        '& > div:first-child': {
            paddingLeft: 0,
            paddingRight: 8,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

export const PayEarly = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        data: { live_withdrawals, summary },
        setSelectedWithdrawals,
    } = DrawDownContainer.useContainer();
    const {
        amount,
        totalSaved,
        marks,
        available,
        availableCredit,
        valueLabelFormat,
        currentStep,
        open,
        anchorEl,
        handlePlus,
        handleMinus,
        handleSlider,
        handlePayOffAll,
        handlePopoverOpen,
        handlePopoverClose,
        handlePayNow,
    } = usePayEarly({ live_withdrawals, summary, setSelectedWithdrawals });

    return (
        <>
            <Header
                title="Pay Early"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('/3/payment')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
                rightActions={
                    <Button onClick={() => navigate('/3/payment')} className={classes.button}>
                        Cancel
                    </Button>
                }
            />
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            maxWidth={280}
                            style={{ marginBottom: 27 }}
                        >
                            Pay Off Withdrawals Early to Get Better Rates
                        </Typography>
                        <Typography variant="caption">
                            {currencyFormatter(availableCredit)} credit available after payment
                        </Typography>
                        <Box display="flex" mt={1} justifyContent="flex-end">
                            <Button
                                className={classes.amountButton}
                                disableElevation
                                variant="contained"
                                size="small"
                                color="neutral"
                                onClick={handlePayOffAll}
                            >
                                Pay off all
                            </Button>
                        </Box>
                    </Box>
                    <Box display="flex" mt={3} pr={1} mb={5}>
                        <IconButton onClick={handleMinus} size="large">
                            <RemoveCircleOutlineIcon fontSize="large" color="secondary" />
                        </IconButton>
                        <Box
                            flex="1"
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography
                                variant="h5"
                                color="secondary"
                                component={Box}
                                className={classes.dollar}
                            >
                                $
                            </Typography>
                            <NumberFormat
                                displayType="text"
                                thousandSeparator
                                value={amount}
                                className={classes.number}
                            />
                        </Box>
                        <IconButton onClick={handlePlus} size="large">
                            <ControlPointIcon fontSize="large" color="secondary" />
                        </IconButton>
                    </Box>
                    <Slider
                        defaultValue={0}
                        valueLabelFormat={valueLabelFormat}
                        min={0}
                        max={available}
                        valueLabelDisplay="auto"
                        classes={{ valueLabel: classes.label }}
                        color="secondary"
                        value={amount}
                        step={null}
                        onChange={handleSlider}
                        marks={marks}
                    />

                    <Box display="flex" justifyContent="space-between" mb={8}>
                        <Typography variant="body2">0</Typography>
                        <Box textAlign="right">
                            <Typography variant="body2">${available}</Typography>
                        </Box>
                    </Box>

                    <Box flex="1" pr={4}>
                        <Box display="flex" flex="1" pb={1.5}>
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                sx={{ width: 80 }}
                                pr={2}
                                // flex="1"
                            >
                                <Typography variant="caption" color="textSecondary">
                                    Original Rate
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                sx={{ width: 64 }}
                                pr={2}
                                // flex="1"
                            >
                                <Typography variant="caption" color="textSecondary">
                                    Early Rate
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                sx={{ width: 72 }}
                                pr={2}
                                // flex="1"
                            >
                                <Typography variant="caption" color="textSecondary">
                                    Amount
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                sx={{ width: 112 }}
                                pr={2}
                                // flex="1"
                            >
                                <Typography variant="caption" color="textSecondary">
                                    Amount Saved
                                </Typography>
                                <IconButton
                                    size="small"
                                    className={classes.infoIconButton}
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Info />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        className={classes.tooltip}
                                    >
                                        Pay-off amount compared with the sum of unpaid schedules,
                                        based on today’s rate
                                    </Typography>
                                </Popover>
                            </Box>
                        </Box>
                        {live_withdrawals.map((item, index) => (
                            <Box
                                key={item.withdrawal_id}
                                className={classes.withdrawalItemContainer}
                            >
                                <Box
                                    mb={1}
                                    display="flex"
                                    flex="1"
                                    className={clsx(
                                        classes.withdrawalItem,
                                        currentStep >= index + 1 ? classes.paid : classes.pending
                                    )}
                                    justifyContent="flex-start"
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        // flex="1"
                                        sx={{ width: 80 }}
                                        pr={2}
                                    >
                                        <Typography variant="caption" color="textSecondary">
                                            {/* {`w${item.withdrawal_id.slice(0, 8)}`} */}
                                            {item.withdrawal_factor_rate}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        // flex="1"
                                        sx={{ width: 64 }}
                                        pr={2}
                                    >
                                        <Typography variant="caption" color="textSecondary">
                                            {item.withdrawal_early_payoff_factor_rate}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        // flex="1"
                                        sx={{ width: 72 }}
                                        pr={2}
                                    >
                                        <Typography variant="caption" color="textSecondary">
                                            {currencyFormatter(
                                                item?.withdrawal_early_payoff_amount ?? 0
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        // flex="1"
                                        sx={{ width: 108 }}
                                        pr={3}
                                    >
                                        <Typography variant="caption" color="textSecondary">
                                            {currencyFormatter(
                                                item?.withdrawal_early_payoff_benefit ?? 0
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                                {currentStep >= index + 1 && (
                                    <IconButton
                                        className={classes.checkCircleIconButton}
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        size="large"
                                    >
                                        <CheckCircleRoundedIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ))}
                    </Box>

                    <Box mt={3} mb={8} display="flex" flex="0 0 auto">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            flex="1"
                        >
                            <Typography variant="body2">Total Amount Saved</Typography>
                            <Typography variant="h5" className={classes.totalSaved}>
                                {currencyFormatter(totalSaved ?? 0)}
                            </Typography>
                        </Box>
                    </Box>

                    <CustomAccordion>
                        <AccordionSummary
                            expandIcon={<ArrowDropDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="body2">Terms and conditions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                The terms and conditions related to your Future Receivable Sales
                                Agreement can be found{' '}
                                <Link
                                    variant="inherit"
                                    color="textSecondary"
                                    href="https://home.golendica.com/agreement-drawdown"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="always"
                                >
                                    here
                                </Link>
                                . Please review before signing.
                            </Typography>
                        </AccordionDetails>
                    </CustomAccordion>
                    <Typography variant="caption">
                        *By clicking ‘PAY NOW’ you agree to all terms and conditions of DrawDown
                        product.
                    </Typography>
                </Box>
            </Body>
            <Toolbar
                title="Total"
                value={currencyFormatter(amount ?? 0)}
                action={
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!currentStep}
                        className={classes.payNowButton}
                        onClick={handlePayNow}
                    >
                        PAY NOW
                    </Button>
                }
            />
        </>
    );
};
