const SIDEBAR_DRAWER_ID = 'sidebar-drawer';
const ACTIVATE_BUTTON_ID = 'btn-fn-activate-01';
const SIDEBAR_BUTTON_ID = 'btn-sidebar-lendica';
const CONSENT_POPOVER_OK_BUTTON_ID = 'btn-fn-consent-popover-proceed-01';
const FUND_NOW_PRODUCT_BUTTON_ID = 'btn-nav-fundnow';
const PAY_LATER_PRODUCT_BUTTON_ID = 'btn-nav-paylater';
const DRAW_DOWN_PRODUCT_BUTTON_ID = 'btn-nav-drawdown';
const REGISTRATION_NEXT_BUTTON_ID = 'btn-appl-next';

// DrawDown
const WITHDRAWAL_BUTTON_ID = 'btn-withdrawal-arrow';
const QUICK_DRAW_BUTTON_ID = 'btn-quick-draw';
const PAYMENT_CENTER_BUTTON_ID = 'btn-payment-center-arrow';
const PAY_EARLY_BUTTON_ID = 'btn-pay-early';
const WITHDRAW_BUTTON_ID = 'btn-withdraw';
const AGREE_AND_DRAW_BUTTON_ID = 'btn-agree-and-draw';

// FunNow and PayLater
const REFRESH_BUTTON_ID = 'btn-refresh-invoices';
const INVOICE_ACTION_BUTTON_ID = 'btn-invoice-action';
const INVOICE_DETAILS_BUTTON_ID = 'btn-invoice-details-action';

const HANDLERS_BY_ID_MAP = {
    [FUND_NOW_PRODUCT_BUTTON_ID]: handleProductClick('FundNow'),
    [PAY_LATER_PRODUCT_BUTTON_ID]: handleProductClick('PayLater'),
    [DRAW_DOWN_PRODUCT_BUTTON_ID]: handleProductClick('DrawDown'),
    [SIDEBAR_BUTTON_ID]: handleSidebarOpenClick('sidebar_click'),
    [ACTIVATE_BUTTON_ID]: handleGenericClick('activate_click'),
    [CONSENT_POPOVER_OK_BUTTON_ID]: handleGenericClick('activate_ok_click'),
    //activate_next_click or activate_submit_click
    [REGISTRATION_NEXT_BUTTON_ID]: handleNextClick(),
    //DrawDown
    [WITHDRAWAL_BUTTON_ID]: handleGenericClick('withdrawal_click'),
    [QUICK_DRAW_BUTTON_ID]: handleGenericClick('quick_draw_click'),
    [PAYMENT_CENTER_BUTTON_ID]: handleGenericClick('payment_center_click'),
    [PAY_EARLY_BUTTON_ID]: handleGenericClick('pay_early_click'),
    [WITHDRAW_BUTTON_ID]: handleGenericClick('withdraw_click'),
    [AGREE_AND_DRAW_BUTTON_ID]: handleGenericClick('agree_and_draw_click'),
    // FunNow and PayLater
    [REFRESH_BUTTON_ID]: handleGenericClick('invoices_refresh_click'),
    [INVOICE_ACTION_BUTTON_ID]: handleInvoiceActionClick('invoice_action_click'),
    [INVOICE_DETAILS_BUTTON_ID]: handleInvoiceDetailsClick('invoice_details_click'),
}

export function initAnalytics(credentials) {
    appendGTagToDocument();

    initGTag(credentials);
    initAnalyticsEventListeners();
}

function appendGTagToDocument() {
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=G-DRKXGBK0D0";
    script.async = true;

    document.body.appendChild(script);
}


let lendicaCredentials = {};
let activeProduct = 'FundNow';
let startTime = 0;
let endTime = 0;

function initGTag(credentials) {
    window.dataLayer = window.dataLayer || [];

    lendicaCredentials = credentials ? { ...credentials, company_access_token: undefined } : {};

    window.gtag = function() {
        dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-DRKXGBK0D0', { transport_url: 'https://gtm-pod.ue.r.appspot.com' });
}

function initAnalyticsEventListeners() {
    attachClickListeners();
}

function attachClickListeners() {
    let handler;

    document.querySelector('widget-commponent').shadowRoot.addEventListener('click', (event) => {
        for (let path of event.path) {
            if (path.id && (handler = HANDLERS_BY_ID_MAP[path.id])) {
                handler(path);
                return;
            }

            if (path.id && (path.id.includes(INVOICE_ACTION_BUTTON_ID))) {
                handler = HANDLERS_BY_ID_MAP[INVOICE_ACTION_BUTTON_ID];
                handler(path);
                return;
            }

            if (path.id && (path.id.includes(REGISTRATION_NEXT_BUTTON_ID))) {
                handler = HANDLERS_BY_ID_MAP[REGISTRATION_NEXT_BUTTON_ID];
                handler(path);
                return;
            }

            if (path.id === SIDEBAR_DRAWER_ID && Boolean(path.ariaHidden)) {
                handleSidebarOutsideClick();
            }
        }
    });
}

function handleGenericClick(eventName) {
    return function() {
        gtag('event', eventName, { ...lendicaCredentials, product_name: activeProduct });
    }
}

function handleInvoiceActionClick(eventName) {
    return function(target) {
        gtag('event', eventName, { ...lendicaCredentials, button_text: target.innerText, product_name: activeProduct });
    }
}

function handleInvoiceDetailsClick(eventName) {
    return function(target) {
        gtag('event', eventName, { ...lendicaCredentials, button_text: target.innerText, product_name: activeProduct });
    }
}

function handleProductClick(product_name) {
    return function() {
        activeProduct = product_name;

        gtag('event', 'product_click', { ...lendicaCredentials, product_name });
    }
}

function handleNextClick() {
    return function(target) {
        const step = target.id.replace('btn-appl-next-', '');

        if (target.innerText.toLowerCase() === 'next') {
            gtag('event', 'activate_next_click', { ...lendicaCredentials, step })
        }

        if (target.innerText.toLowerCase() === 'submit') {
            gtag('event', 'activate_submit_click', { ...lendicaCredentials, step })
        }
    }
}

function handleSidebarOpenClick(eventName) {
    return function() {
        startTime = performance.now();
        handleGenericClick(eventName);
    }
}

function handleSidebarOutsideClick() {
    endTime = performance.now();
    const timeOfUse = Math.round((endTime - startTime) / (1000 * 60));

    gtag('event', 'sidebar_outside_click', { ...lendicaCredentials, product_name: activeProduct, time_of_use: `${timeOfUse} min` });
}