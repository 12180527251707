import axios from 'axios';
import { getBackendURL } from '@lendica/utils';

export const AxiosClient = {
    create(env) {
        return axios.create({
            baseURL: getBackendURL(env),
            timeout: 29000,
        });
    },
};
