import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext, useDataContext } from '../../../AppContext';

import {
    Overlay,
    OverlayFieldset,
    OverlayKeypoint,
    OverlayAction,
    MoreInformation,
    Terms,
    FootNote,
} from '@lendica/components';
import { Loader } from '../../components';
import { percentageFormatter, currencyFormatter } from '@lendica/utils';

import { activateSubmit } from '../../../api';

export const FNActivate = () => {
    const {
        appState: { fnIsLoading },
        setAppState,
        updateData,
    } = useAppContext();
    const [data] = useDataContext();

    const [agreeTerms, setAgreeTerms] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            setAppState(currentState => ({ ...currentState, fnIsLoading: true }));
            if (data?.company?.lendica_token) {
                await activateSubmit(
                    {
                        lendica_token: data?.company?.lendica_token,
                        approval: data?.fundnow?.approval,
                    },
                    'fundnow'
                );
                await updateData();
                navigate('/1');
            } else {
                throw new Error('Missing auth token, try refreshing the page.');
            }
        } catch (error) {
            setAppState(currentState => ({
                ...currentState,
                snackbar: {
                    open: true,
                    message: `There was an error submitting the agreement, please try again later. ${error}`,
                },
            }));
        } finally {
            setAppState(currentState => ({
                ...currentState,
                fnIsLoading: false,
            }));
        }
    };

    const handleOverlayClose = () => {
        navigate('/1');
    };

    return fnIsLoading ? (
        <Loader />
    ) : (
        <Overlay title="Agree and activate" handleClose={handleOverlayClose}>
            <OverlayFieldset subtitle="What you'll get">
                <OverlayKeypoint
                    summary="Maximum funding"
                    details={currencyFormatter(
                        data?.fundnow?.approval?.approval_amount ?? 'Not Available'
                    )}
                />
                <OverlayKeypoint
                    summary="Fees for 30 days*"
                    details={percentageFormatter(data?.fundnow?.approval?.fees ?? 'Not Available')}
                />
                <OverlayKeypoint
                    summary="Percentage of invoice totals you’ll receive upfront"
                    details={percentageFormatter(
                        data?.fundnow?.approval?.advance_rate ?? 'Not Available'
                    )}
                />
            </OverlayFieldset>
            <MoreInformation
                items={[
                    {
                        summary: 'Schedule',
                        details:
                            'You will receive the remaining portion of your invoice after your client makes the full payment.',
                    },
                    {
                        summary: 'What if my Customer doesn’t pay in time?',
                        details:
                            'After a 90 day period, Lendica will collect the full amount plus financing fees from you.',
                    },
                    {
                        summary: 'What is the most I’ll pay?',
                        details:
                            'You will pay at most a 12.5% fee after a 90 day period if your client does not pay the invoice in full.',
                    },
                    {
                        summary: 'Prepayment',
                        details:
                            'If you pay off early, you will not have to pay a penalty and you may be entitled to a refund of part of the finance charge.',
                    },
                ]}
            />
            <Terms
                termsToSign={{
                    text: `I agree to the FundNow Terms and Services and confirm that I am a controlling member of ${
                        data?.company?.name ?? 'the company'
                    }`,
                    url: 'https://home.golendica.com/agreement-fundnow',
                }}
                checkId="cbox-fn-approval-terms"
                checked={agreeTerms}
                onChange={e => setAgreeTerms(e.target.checked)}
            />
            <FootNote lines={['*Fees are subject to change with each invoice funded.']} />
            <OverlayAction
                action="Activate"
                actionId="btn-fn-approval-activate-01"
                disabled={!agreeTerms}
                onClick={handleSubmit}
            />
        </Overlay>
    );
};
