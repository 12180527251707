import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from '@lendica/loan-form';
import { Header, Body } from '../../components';

import { useAppContext } from '../../../AppContext';
import { getBackendURL } from '@lendica/utils';

const applicationStatuses = {
    inProgress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

export const IPayLaterApplication = () => {
    const {
        appState: { environment, applyOnComplete },
        toggleHeaderVisibility,
    } = useAppContext();

    const { applicationId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        toggleHeaderVisibility();
        return () => toggleHeaderVisibility();
    }, []);

    const handleFormComplete = ({ application_status }) => {
        if (applyOnComplete && typeof applyOnComplete === 'function') {
            applyOnComplete();
        }
        if (application_status === applicationStatuses.approved) {
            navigate(`/paylater/success`);
            return;
        }
        navigate('/paylater/submitted');
    };

    return (
        <>
            <Header />
            <Body>
                <Form
                    applicationId={applicationId}
                    baseURL={getBackendURL(environment)}
                    onExit={() => {
                        navigate('/paylater');
                    }}
                    onComplete={handleFormComplete}
                />
            </Body>
        </>
    );
};
